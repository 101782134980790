import React from "react";
import PropTypes from "prop-types";
import Chart from "chart.js";

import demoData from "./demo";

class FinalStatisticsChart extends React.Component {
  rgbaAgents = [
    alpha => `rgba(63, 83, 175, ${alpha})`,
    alpha => `rgba(245, 0, 87, ${alpha})`,
    alpha => `rgba(138, 193, 72, ${alpha})`,
    alpha => `rgba(255, 152, 0, ${alpha})`,
    alpha => `rgba(255, 238, 88, ${alpha})`
  ];

  rewardDataset = (i, name, data) => {
    return {
      label: name,
      borderWidth: 0.5,
      borderColor: this.rgbaAgents[i](0.6),
      backgroundColor: this.rgbaAgents[i](0.6),
      radius: 0,
      fill: false,
      tension: 0, // no bezier
      yAxisID: "revenue-axis",
      data: data
    };
  };

  priceDataset = (i, name, data) => {
    return {
      label: name,
      borderWidth: 2,
      borderDash: [5, 5],
      backgroundColor: this.rgbaAgents[i](0.6),
      borderColor: this.rgbaAgents[i](0.6),
      radius: 0,
      fill: false,
      tension: 0, // no bezier
      yAxisID: "price-axis",
      data: data
    };
  };

  constructor(props) {
    super(props);
    this.chart = null;
    this.players = null;
  }

  movingAvg = dataArray => {
    const wSize = this.props.average; // window size
    if (!wSize) {
      return dataArray;
    }
    var wSum = 0.0;
    var wIni = 0;
    var lastItem = 0;
    return dataArray.map((point, turn) => {
      wIni = turn - wSize;
      lastItem = turn >= wSize ? dataArray[wIni].y : 0;
      wSum += point.y - lastItem;
      return { t: turn, y: wSum / wSize };
    });
  };

  generateDatasets(playersArr) {
    return playersArr
      .map((info, i) =>
        this.rewardDataset(
          i,
          `${info.name} Revenue`,
          this.movingAvg(info.agent.rewardHistory)
        )
      )
      .concat(
        playersArr.map((info, i) =>
          this.priceDataset(i, `${info.name} Price`, info.agent.priceHistory)
        )
      );
  }

  componentDidMount() {
    const { players } = this.props;

    var ctx = document.getElementById("statisticsChart").getContext("2d");
    this.chart = new Chart(ctx, {
      type: "line",
      data: {
        datasets: this.generateDatasets([
          players.optimal,
          players.player,
          players.agent
        ])
      },
      options: {
        animation: { duration: 0 },
        legend: { position: "bottom" },
        tooltips: { enabled: true },
        scales: {
          xAxes: [
            {
              type: "time",
              display: false
            }
          ],
          yAxes: [
            {
              id: "revenue-axis",
              position: "left",
              type: "linear",
              display: true,
              ticks: { beginAtZero: true },
              scaleLabel: {
                display: true,
                labelString: "Instant Revenue"
              }
            },
            {
              id: "price-axis",
              position: "right",
              type: "linear",
              display: true,
              ticks: { beginAtZero: true },
              gridLines: { display: false },
              scaleLabel: {
                display: true,
                labelString: "Selected Price"
              }
            }
          ]
        }
      }
    });
    this.chart.update();
  }

  render() {
    if (this.chart !== null) {
      const { tourState, players } = this.props;
      let playersComparison;
      if (tourState.visible) {
        playersComparison = demoData.finalStatisticsDemoPlayers;
      } else {
        playersComparison = [players.optimal, players.player, players.agent];
      }
      const dataSets = this.generateDatasets(playersComparison);
      // datasets must be updated through their data prop
      for (let i = 0; i < dataSets.length; i++) {
        this.chart.data.datasets[i].data = dataSets[i].data;
      }

      if (this.props.showRevenues) {
        [0, 1, 2].forEach(v => (this.chart.getDatasetMeta(v).hidden = null));
      } else {
        [0, 1, 2].forEach(v => (this.chart.getDatasetMeta(v).hidden = true));
      }
      if (this.props.showPrices) {
        [3, 4, 5].forEach(v => (this.chart.getDatasetMeta(v).hidden = null));
      } else {
        [3, 4, 5].forEach(v => (this.chart.getDatasetMeta(v).hidden = true));
      }

      this.chart.update();
    }
    return <canvas id="statisticsChart" width="600" height="300" />;
  }
}

FinalStatisticsChart.propTypes = {
  players: PropTypes.object,
  average: PropTypes.number,
  showPrices: PropTypes.bool,
  showRevenues: PropTypes.bool
};

export default FinalStatisticsChart;
