import React from "react";
import OptimalMarketAgent from "./agents/optimalMarket";
// import UCB1Agent from "./agents/ucb1";
// import OptimalAgent from "./agents/optimal";
// import SoftmaxAgent from "./agents/softmax";
// import ConstantAgent from "./agents/constant";
// import RandomAgent from "./agents/random";
// import EpislonGreedyAgent from "./agents/epsilonGreedy";
import SoftmaxGreedyAgent from "./agents/softmaxGreedy";
import MainWindow from "./MainWindow";
import DemandCurve from "./market/demandCurve";
import NormalDistribution from "./probabilityDistributions/normal";
import DistributionShifter from "./probabilityDistributions/shifter";
import Tryonumjs from "./tryonumjs";

const GAME_TURNS = 3 * 365;
const AGENT_TURNS_PER_ACTION = 15;
const FRAME_DURATION_LOW = Math.round(1000 / 15); // FPS: 15
const FRAME_DURATION_HIGH = Math.round(1000 / 30); // FPS: 30

class Game extends React.Component {
  mainWindow = null; // reference to main window node

  constructor(props) {
    super(props);
    this.frame_duration = FRAME_DURATION_LOW;
    this.productName = "groceries";
    this.initGame();
    this.state = this.getInitialState();

    // Show tour on load
    this.state.tourState.visible = false;
    this.state.showDescription = true;
    this.state.firstVisit = true;
    this.agentComparison = []; // see buildAgentsComparison()

    this.actions = {
      restartGame: this.restartGame,
      selectPrice: this.selectPrice,
      togglePlay: this.togglePlay,
      selectProduct: this.selectProduct,
      openTour: this.openTour,
      closeTour: this.closeTour,
      setTourState: this.setTourState,
      toggleSpeed: this.toggleSpeed,
      toggleDescription: this.toggleDescription,
      disableOverlay: this.disableOverlay
    };
  }

  toggleDescription = () => {
    this.setState({
      showDescription: !this.state.showDescription
    });
  };

  disableOverlay = () => {
    this.setState({
      showDescription: false,
      firstVisit: false,
      tourState: {
        ...this.state.tourState,
        visible: true
      }
    });
  };

  openTour = () => {
    if (this.state.gameTimeout !== null) {
      // If the game is running, pause it
      this.togglePlay();
    }
    this.setState({ tourState: { visible: true } });
  };

  closeTour = () => {
    this.setState({ tourState: { visible: false } });
  };

  setTourState = (showFinalStatistics, showCostChange) => {
    if (
      this.state.tourState.visible &&
      (this.state.tourState.showFinalStatistics !== showFinalStatistics ||
        this.state.tourState.showCostChange !== showCostChange)
    ) {
      this.setState({
        tourState: {
          visible: true,
          showFinalStatistics: showFinalStatistics,
          showCostChange: showCostChange
        }
      });
    }
  };

  toggleSpeed = () => {
    let changeToHigh = this.frame_duration === FRAME_DURATION_LOW;
    this.frame_duration = changeToHigh
      ? FRAME_DURATION_HIGH
      : FRAME_DURATION_LOW;
    this.setState({ highSpeed: changeToHigh });
  };

  getInitialState = () => {
    return {
      nTurns: 0,
      gameProgress: 0,
      gameTimeout: null,
      tourState: {
        visible: false,
        showFinalStatistics: false,
        showCostChange: false
      },
      optimalPrice: this.prices[0],
      // expectedRevenues: new Array(this.prices.length).fill(0),
      productName: this.productName,
      productCost: this.getUpdatedProductCost(true),
      prices: this.prices,
      player: {
        selectedPrice: this.prices.slice(-1)[0], // avoid negative revenue on start
        turnRevenue: 0,
        totalRevenue: 0,
        turnSales: 0,
        totalSales: 0,
        rewardHistory: [],
        salesHistory: [],
        priceHistory: []
      },
      agent: {
        selectedPrice: this.prices.slice(-1)[0], // avoid negative revenue on start
        turnRevenue: 0,
        totalRevenue: 0,
        turnSales: 0,
        totalSales: 0,
        rewardHistory: [],
        salesHistory: [],
        priceHistory: []
      }
    };
  };

  setFruitGame() {
    this.costRange = { min: 10, max: 17 };
    this.prices = [15, 16, 18, 21, 25, 30];

    this.market = {
      populationDistribution: new DistributionShifter(NormalDistribution, [
        [Math.floor(Math.random() * 200 + 900), 200], //Summer
        [Math.floor(Math.random() * 100 + 450), 80], //Fall
        [Math.floor(Math.random() * 100 + 250), 40], //Winter
        [Math.floor(Math.random() * 200 + 600), 100], //Spring
        [Math.floor(Math.random() * 200 + 600), 100] //Spring
      ]),
      demandCurve: new DemandCurve(this.prices, [
        new NormalDistribution(4, 0.05),
        new NormalDistribution(4, 0.05),
        new NormalDistribution(2.5, 0.03),
        new NormalDistribution(1.3, 0.02),
        new NormalDistribution(0.8, 0.02),
        new NormalDistribution(0.3, 0.03)
      ])
    };

    this.agent = new SoftmaxGreedyAgent(this.prices, 0.05, true, true);
  }

  setServiceGame() {
    this.costRange = { min: 4, max: 8 };
    this.prices = [6, 7, 8, 10, 13, 17];

    this.market = {
      populationDistribution: new DistributionShifter(NormalDistribution, [
        [Math.floor(Math.random() * 5000 + 5000), 1000], //Summer
        [Math.floor(Math.random() * 2000 + 2000), 400], //Fall
        [Math.floor(Math.random() * 1000 + 1000), 200], //Winter
        [Math.floor(Math.random() * 2000 + 2000), 400], //Spring
        [Math.floor(Math.random() * 2000 + 2000), 400] //Spring
      ]),
      demandCurve: new DemandCurve(this.prices, [
        new NormalDistribution(1.5, 0.01),
        new NormalDistribution(2.3, 0.02),
        new NormalDistribution(1.2, 0.01),
        new NormalDistribution(0.8, 0.01),
        new NormalDistribution(0.5, 0.01),
        new NormalDistribution(0.3, 0.01)
      ])
    };
    this.agent = new SoftmaxGreedyAgent(this.prices, 0.05, true, true);
  }

  setWineGame() {
    this.costRange = { min: 25, max: 50 };
    this.prices = [46, 47, 50, 55, 65, 80];

    this.market = {
      populationDistribution: new DistributionShifter(NormalDistribution, [
        [Math.floor(Math.random() * 200 + 200), 30], //Spring
        [Math.floor(Math.random() * 100 + 100), 60], //Winter
        [Math.floor(Math.random() * 200 + 200), 30], //Spring
        [Math.floor(Math.random() * 200 + 200), 40], //Summer
        [Math.floor(Math.random() * 100 + 100), 20] //Fall
      ]),
      demandCurve: new DemandCurve(this.prices, [
        new NormalDistribution(0.7, 0.02),
        new NormalDistribution(0.9, 0.02),
        new NormalDistribution(1.2, 0.03),
        new NormalDistribution(0.5, 0.01),
        new NormalDistribution(0.6, 0.02),
        new NormalDistribution(0.4, 0.02)
      ])
    };
    this.agent = new SoftmaxGreedyAgent(this.prices, 0.05, true, true);
  }

  setLuxuryGame() {
    this.costRange = { min: 1750, max: 2000 };
    this.prices = [1850, 1900, 2000, 2100, 2300, 2500];

    this.market = {
      populationDistribution: new DistributionShifter(NormalDistribution, [
        [Math.floor(Math.random() * 20 + 20), 4], //Spring
        [Math.floor(Math.random() * 10 + 10), 2], //Winter
        [Math.floor(Math.random() * 20 + 20), 4], //Spring
        [Math.floor(Math.random() * 20 + 20), 4], //Summer
        [Math.floor(Math.random() * 10 + 10), 2] //Fall
      ]),
      demandCurve: new DemandCurve(this.prices, [
        new NormalDistribution(0.2, 0.01),
        new NormalDistribution(0.4, 0.01),
        new NormalDistribution(0.45, 0.04),
        new NormalDistribution(0.23, 0.03),
        new NormalDistribution(0.12, 0.01),
        new NormalDistribution(0.1, 0.005)
      ])
    };
    this.agent = new SoftmaxGreedyAgent(this.prices, 0.1, true, true);
  }

  initGame = () => {
    if (this.productName === "groceries") {
      this.setFruitGame();
    } else if (this.productName === "service") {
      this.setServiceGame();
    } else if (this.productName === "refined") {
      this.setWineGame();
    } else if (this.productName === "luxury") {
      this.setLuxuryGame();
    }
    this.optimalAgent = new OptimalMarketAgent(this.prices, this.market);

    this.nextCostUpdateProgress = 0;
    this.costUpdates = [0.3, 0.6, 0.8].reverse();
  };

  constructAgents(agentClass, argsList) {
    return argsList.map(args => new agentClass(this.prices, ...args));
  }

  buildAgentsComparison = () => {
    // let optimalAgent = this.constructAgents(OptimalMarketAgent, [
    //   [this.market]
    // ]);
    // let greedyAgents = this.constructAgents(EpsilonGreedyAgent, [
    //   // [0.1, true]
    //   //[0.05, true],
    //   //[0.01, true],
    //   //[0, true]
    // ]);
    // let softmaxAgents = this.constructAgents(SoftmaxAgent, [
    //   // [1e-1, 2e-1, true, true, 1, "moving"],
    //   // [1e-1, 2, true, true, 0.9, "moving"],
    //   // [1e-1, 2, true, true, 0.8, "moving"]
    // ]);
    // // let ucb1Agents = this.constructAgents(UCB1Agent, [
    // //   [4, false],
    // //   [undefined, true]
    // // ]);
    // // var randomAgents = this.constructAgents(RandomAgent, [[]]);
    // // var constantAgents = this.constructAgents(
    // //   ConstantAgent,
    // //   this.prices.map(p => [p])
    // // );
    // var softmaxGreedyAgents = this.constructAgents(SoftmaxGreedyAgent, [
    //   [0.1, true, true],
    //   [0.1, false, true]
    // ]);
    // let allAgents = optimalAgent.concat(
    //   // greedyAgents,
    //   // softmaxAgents
    //   // ucb1Agents,
    //   //constantAgents,
    //   //randomAgents,
    //   softmaxGreedyAgents
    // );
    // this.agentComparison = allAgents.map(agent => {
    //   return {
    //     name: agent.getName(),
    //     agent: agent
    //   };
    // });
  };

  restartGame = () => {
    this.stopGame();
    this.initGame();
    this.setState(this.getInitialState());
  };

  stopGame = () => {
    clearTimeout(this.state.gameTimeout);
    this.setState({ gameTimeout: null });
    // this.showPerformances();
  };

  togglePlay = () => {
    // timeout null indicates game paused
    if (this.state.gameTimeout === null) {
      this.setState(
        {
          currentFrameEndTimestamp: new Date().getTime() + this.frame_duration
        },
        this.updateGame
      );
    } else {
      this.stopGame();
    }
  };

  // Returns a handler function
  selectProduct = productName => {
    return (e, target) => {
      this.stopGame();
      this.productName = productName;
      this.initGame();
      this.setState(this.getInitialState());
    };
  };

  showPerformances() {
    if (this.agentComparison.length) {
      let optimalRevenue = this.optimalAgent.totalRevenue();
      console.log(`Optimal revenue: ${optimalRevenue}`);
      this.agentComparison.forEach(info => {
        let revenue = info.agent.totalRevenue();
        console.log(
          `${info.name} revenue: ${revenue} (${(
            (100 * revenue) /
            optimalRevenue
          ).toFixed(2)}%)`
        );
      });

      console.log(
        `Player revenue (%): ${(
          (100 * this.state.player.totalRevenue) /
          optimalRevenue
        ).toFixed(2)}%)`
      );
      console.log(
        `Agent revenue (%): ${(
          (100 * this.state.agent.totalRevenue) /
          optimalRevenue
        ).toFixed(2)}%)`
      );
    }
  }

  calculateSales(price) {
    // var { populationDistribution, purchaseDistribution } = this.market;
    // var potentialBuyers = populationDistribution.sample();
    // var buyersRatio = 1 - purchaseDistribution.cdf(price);
    // return Math.floor(potentialBuyers * buyersRatio);

    const { populationDistribution, demandCurve } = this.market;
    return populationDistribution.sample() * demandCurve.getSales(price);
  }

  getUpdatedProductCost(initial = false) {
    if (!this.state || this.state.gameProgress >= this.nextCostUpdateProgress) {
      // The cost to use next to this step is selected with anticipation
      // so that it can be shown before changing in the InfoBoard
      let newProductCost = initial
        ? Tryonumjs.randomInt(this.costRange)
        : this.nextProductCost;

      do {
        // To prevent selecting the same cost
        this.nextProductCost = Tryonumjs.randomInt(this.costRange);
      } while (this.nextProductCost === newProductCost);

      this.nextCostUpdateProgress = this.costUpdates.pop();
      return newProductCost;
    } else {
      return this.state.productCost;
    }
  }

  updateGame = () => {
    // Calculate agent's revenue
    //
    let agentPrice = this.state.agent.selectedPrice;
    let productCost = this.state.productCost;
    if (this.state.nTurns % AGENT_TURNS_PER_ACTION === 0) {
      agentPrice = this.agent.selectPrice();
    }
    const agentSales = this.calculateSales(agentPrice);
    const agentRevenue = agentSales * (agentPrice - productCost);
    const totalAgentRevenue = this.state.agent.totalRevenue + agentRevenue;

    // Reward agent
    this.agent.reward(agentRevenue);
    this.agent.updateProductCost(productCost);
    this.agent.updateSales(agentSales);

    // Calculate player's revenue
    const playerPrice = this.state.player.selectedPrice;

    const playerSales = this.calculateSales(playerPrice);
    const playerRevenue = playerSales * (playerPrice - productCost);
    const totalPlayerRevenue = this.state.player.totalRevenue + playerRevenue;

    // Optimal price calculator
    this.optimalAgent.updateCost(productCost);

    // Agents comparison
    if (this.state.nTurns % AGENT_TURNS_PER_ACTION === 0) {
      this.agentComparison.forEach(info => {
        info.selectedPrice = info.agent.selectPrice();
      });
    }
    this.agentComparison.forEach(info => {
      const agentPrice = info.selectedPrice;
      const agentSales = this.calculateSales(agentPrice);
      const agentRevenue = agentSales * (agentPrice - productCost);
      info.agent.reward(agentRevenue);
      info.agent.updateProductCost(productCost);
      info.agent.updateSales(agentSales);
    });

    // Reward optimal agent
    const optimalAgentPrice = this.state.optimalPrice;
    const optimalAgentRevenue =
      this.calculateSales(optimalAgentPrice) *
      (optimalAgentPrice - productCost);
    this.optimalAgent.reward(optimalAgentRevenue);

    const currentTimestamp = new Date().getTime();
    const frameRemainingTime = Math.max(
      0,
      this.state.currentFrameEndTimestamp - currentTimestamp
    );
    this.setState({
      currentFrameEndTimestamp:
        this.state.currentFrameEndTimestamp + this.frame_duration
    });

    // Update game state
    this.setState({
      nTurns: this.state.nTurns + 1,
      gameProgress: this.state.nTurns / GAME_TURNS,
      gameTimeout: setTimeout(this.updateGame, frameRemainingTime),
      optimalPrice: this.optimalAgent.selectPrice(),
      // expectedRevenues: this.optimalAgent.calculateExpectedRevenues(),
      productCost: this.getUpdatedProductCost(),
      nextProductCost: this.nextProductCost,
      nextCostUpdateProgress: this.nextCostUpdateProgress,
      player: {
        ...this.state.player,
        turnSales: playerSales,
        totalSales: this.state.player.totalSales + playerSales,
        turnRevenue: playerRevenue,
        totalRevenue: totalPlayerRevenue,
        rewardHistory: [
          ...this.state.player.rewardHistory,
          { t: this.state.nTurns, y: playerRevenue }
        ],
        salesHistory: [
          ...this.state.player.salesHistory,
          { t: this.state.nTurns, y: playerSales }
        ],
        priceHistory: [
          ...this.state.player.priceHistory,
          { t: this.state.nTurns, y: playerPrice }
        ]
      },
      agent: {
        ...this.state.agent,
        selectedPrice: agentPrice,
        turnSales: agentSales,
        totalSales: this.state.agent.totalSales + agentSales,
        turnRevenue: agentRevenue,
        totalRevenue: totalAgentRevenue,
        rewardHistory: [
          ...this.state.agent.rewardHistory,
          { t: this.state.nTurns, y: agentRevenue }
        ],
        salesHistory: [
          ...this.state.agent.salesHistory,
          { t: this.state.nTurns, y: agentSales }
        ],
        priceHistory: [
          ...this.state.agent.priceHistory,
          { t: this.state.nTurns, y: agentPrice }
        ]
      }
    });

    if (this.state.nTurns >= GAME_TURNS) {
      // Finish game
      this.stopGame();
    } else {
      this.market.populationDistribution.update(this.state.gameProgress);
      // this.market.purchaseDistribution.update(
      //   this.state.gameProgress
      // );
    }
  };

  selectPrice = price => {
    this.setState({
      player: {
        ...this.state.player,
        selectedPrice: price
      }
    });
  };

  render() {
    return (
      <MainWindow
        innerRef={node => (this.mainWindow = node)}
        gameState={this.state}
        actions={this.actions}
        gameTurns={GAME_TURNS}
        playersComparison={{
          optimal: { name: "Optimal", agent: this.optimalAgent },
          player: { name: "Player", agent: this.state.player },
          agent: { name: "Agent", agent: this.state.agent }
        }}
      />
    );
  }
}

export default Game;
