import React from "react";
import PropTypes from "prop-types";
import Chart from "chart.js";

class GameProgressBar extends React.Component {
  rgbaProgress = alpha => `rgba(63, 83, 175, ${alpha})`;
  rgbaRemaining = alpha => `rgba(220, 220, 220, ${alpha})`;

  progressDataset = {
    backgroundColor: this.rgbaProgress(1),
    data: []
  };

  remainingDataset = {
    backgroundColor: this.rgbaRemaining(1),
    data: []
  };

  constructor(props) {
    super(props);
    this.chart = null;
  }

  componentDidMount() {
    const { showDemo } = this.props;

    if (showDemo) {
      this.progressDataset.data = [0.2];
      this.remainingDataset.data = [0.8];
    }

    var ctx = document.getElementById("progressChart").getContext("2d");
    this.chart = new Chart(ctx, {
      type: "horizontalBar",
      data: {
        labels: [],
        datasets: [this.progressDataset, this.remainingDataset]
      },
      options: {
        title: {
          display: false,
          text: "Game Progress",
          padding: 0,
          fontSize: 10
        },
        legend: { display: false },
        tooltips: { enabled: false },
        animation: { duration: 100 },
        scales: {
          xAxes: [
            {
              display: false,
              stacked: true,
              gridLines: { display: false }
            }
          ],
          yAxes: [
            {
              stacked: true,
              display: false,
              gridLines: { display: false }
            }
          ]
        }
      }
    });
  }

  render() {
    const { showDemo, tutorialClassName, gameProgress } = this.props;

    if (showDemo) {
      this.progressDataset.data = [0.2];
      this.remainingDataset.data = [0.8];
    } else {
      this.progressDataset.data = [gameProgress];
      this.remainingDataset.data = [1 - gameProgress];
    }
    // first render() is before componentDidMount()
    if (this.chart !== null) {
      this.chart.update();
    }

    return (
      <canvas
        className={tutorialClassName}
        id="progressChart"
        width="400"
        height="5"
      />
    );
  }
}

GameProgressBar.propTypes = {
  gameProgress: PropTypes.number
};

export default GameProgressBar;
