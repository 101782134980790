import React from "react";
import PropTypes from "prop-types";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

// Avoid automatic registering of the plugin
Chart.plugins.unregister(ChartDataLabels);

class BarChart extends React.Component {
  rgbaPlayer = alpha => `rgba(63, 83, 175, ${alpha})`;
  rgbaAgent = alpha => `rgba(226, 49, 90, ${alpha})`;
  playerTotalRevenue = 0;
  agentTotalRevenue = 0;

  savedData = null;

  playerDataset = {
    datalabels: {
      color: "#FFFFFF",
      clamp: true,
      align: "center",
      formatter: (value, context) => {
        return `Your Revenue (total: ${Math.round(this.playerTotalRevenue)})`;
      }
    },
    backgroundColor: this.rgbaPlayer(1),
    borderColor: this.rgbaPlayer(1),
    borderWidth: 0,
    data: []
  };

  agentDataset = {
    datalabels: {
      color: "#FFFFFF",
      clamp: true,
      align: "center",
      formatter: (value, context) => {
        return `Agent Revenue (total: ${Math.round(this.agentTotalRevenue)})`;
      }
    },

    backgroundColor: this.rgbaAgent(1),
    borderColor: this.rgbaAgent(1),
    borderWidth: 0,
    data: []
  };

  constructor(props) {
    super(props);
    this.chart = null;
  }

  componentDidMount() {
    const { showDemo } = this.props;
    if (showDemo) {
      this.loadDemo();
    }

    var ctx = document.getElementById("barChart").getContext("2d");
    this.chart = new Chart(ctx, {
      type: "horizontalBar",
      plugins: [ChartDataLabels],
      data: {
        labels: [],
        datasets: [this.playerDataset, this.agentDataset]
      },
      options: {
        title: {
          display: false,
          text: "Total Revenue (%)",
          padding: 0,
          fontSize: 10
        },
        legend: { display: false },
        tooltips: { enabled: false },
        animation: { duration: 100 },
        scales: {
          xAxes: [
            {
              display: false,
              stacked: true,
              gridLines: { display: false }
            }
          ],
          yAxes: [
            {
              stacked: true,
              display: false,
              gridLines: { display: false }
            }
          ]
        }
      }
    });
  }

  loadDemo() {
    if (this.savedData === null) {
      this.savedData = {
        playerTotalRevenue: this.playerTotalRevenue,
        agentTotalRevenue: this.agentTotalRevenue,
        playerData: this.playerDataset.data,
        agentData: this.agentDataset.data
      };
      this.playerTotalRevenue = 221913.6;
      this.playerDataset.data = [0.6];
      this.agentTotalRevenue = 147942.4;
      this.agentDataset.data = [0.4];
    }
  }

  unloadDemo() {
    if (this.savedData !== null) {
      this.playerTotalRevenue = this.savedData.playerTotalRevenue;
      this.playerDataset.data = this.savedData.playerData;
      this.agentTotalRevenue = this.savedData.agentTotalRevenue;
      this.agentDataset.data = this.savedData.agentData;
      this.savedData = null;
    }
  }


  clamp(x, min, max) {
    return Math.max(min, Math.min(x, max));
  }

  render() {
    const { showDemo, playerTotalRevenue, agentTotalRevenue } = this.props;
    if (showDemo) {
      this.loadDemo();
    } else {
      this.unloadDemo();
      this.playerTotalRevenue = playerTotalRevenue;
      this.agentTotalRevenue = agentTotalRevenue;
    }

    const minRevenue = Math.min(0, this.playerTotalRevenue, this.agentTotalRevenue);
    const totalRev = this.playerTotalRevenue + this.agentTotalRevenue - 2 * minRevenue;

    // render() is executed before componentDidMount and on any button click event
    if (totalRev) {
      const playerShare = this.clamp(
        Math.round((this.playerTotalRevenue - minRevenue) / totalRev * 1000) / 1000,
        0.25, 0.75
      );
      this.playerDataset.data = [playerShare];
      this.agentDataset.data = [1 - playerShare];
    } else {
      this.playerDataset.data = [0.5];
      this.agentDataset.data = [0.5];
    }

    if (this.chart !== null) {
      this.chart.update();
    }

    return <canvas id="barChart" width="400" height="15" />;
  }
}

BarChart.propTypes = {
  playerTotalRevenue: PropTypes.number,
  agentTotalRevenue: PropTypes.number
};

export default BarChart;
