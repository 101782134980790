import Tryonumjs from "../tryonumjs";

export default class BaseAgent {
  constructor(prices, averageMethod = "simple") {
    if (this.constructor === BaseAgent) {
      throw new TypeError(
        'Abstract class "BaseAgent" cannot be instantiated directly.'
      );
    }
    this.prices = prices;
    this.nPrices = prices.length;
    this.pricesIdx = Array.from({ length: this.nPrices }, (v, k) => k);
    this.turn = 0;

    this.priceHistory = [];
    this.rewardHistory = [];
    this.salesHistory = [];
    this.productCost = 0;
    this._lastSelectedPrice = this.prices.slice(-1)[0]; // start with last price
  }

  getName() {
    throw new Error("getName() has not been implemented yet");
  }

  selectPrice() {
    this._lastSelectedPrice = this._selectPrice();
    return this._lastSelectedPrice;
  }

  reward(reward) {
    this._reward(reward);
    this.rewardHistory.push({ t: this.turn, y: reward });
    this.priceHistory.push({ t: this.turn, y: this._lastSelectedPrice });
    this.turn += 1;
  }

  updateSales(sales) {
    this.salesHistory.push({ t: this.turn, y: sales });
    this._updateSales(sales);
  }

  updateProductCost(cost) {
    this.productCost = cost;
  }

  totalRevenue() {
    return Tryonumjs.sum(this.rewardHistory.map((item, i) => item.y));
  }

  _selectPrice() {
    throw new Error("_selectPrice() has not been implemented yet");
  }

  _reward(reward) {
    throw new Error("_reward() has not been implemented yet");
  }

  _updateSales(sales) {
    // optionally implement a handler for this
  }

  simpleAverage(oldAverage, oldCount, newSample) {
    return oldAverage + (newSample - oldAverage) / (oldCount + 1);
  }

  decayingAverage(oldAverage, decayingFactor, newSample) {
    return oldAverage * decayingFactor + (1 - decayingFactor) * newSample;
  }

  movingAverage(oldAverage, oldSamples, maxSamples, newSample) {
    if (oldSamples.length === maxSamples) {
      const oldestSample = oldSamples.shift();
      oldSamples.push(newSample);
      return oldAverage + (newSample - oldestSample) / maxSamples;
    }
    oldSamples.push(newSample);
    return oldAverage + (newSample - oldAverage) / oldSamples.length;
  }
}
