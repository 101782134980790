import React from "react";
import PropTypes from "prop-types";
import Chart from "chart.js";

import demoData from "./demo";

class LineChart extends React.Component {
  rgbaRevenue = alpha => `rgba(63, 83, 175, ${alpha})`;
  rgbaSales = alpha => `rgba(150, 150, 150, ${alpha})`;

  revenueDataset = {
    label: "Revenue (per turn)",
    borderColor: this.rgbaRevenue(1),
    backgroundColor: this.rgbaRevenue(1),
    radius: 0,
    fill: false,
    tension: 0, // no bezier
    yAxisID: "revenue-axis",
    data: []
  };

  salesDataset = {
    label: "Sales (per turn)",
    borderColor: this.rgbaSales(1),
    backgroundColor: this.rgbaSales(1),
    radius: 0,
    fill: false,
    tension: 0, // no bezier
    yAxisID: "sales-axis",
    data: []
  };

  savedData = null;

  movingAvg = (dataArray, wSize) => {
    var wSum = 0.0;
    var wIni = 0;
    var lastItem = 0;
    return dataArray.map((point, turn) => {
      wIni = turn - wSize;
      lastItem = turn >= wSize ? dataArray[wIni].y : 0;
      wSum += point.y - lastItem;
      return { t: turn, y: wSum / wSize };
    });
  };

  constructor(props) {
    super(props);
    this.chart = null;
  }

  componentDidMount() {
    const { showDemo } = this.props;
    if (showDemo) {
      this.loadDemo();
    }

    var ctx = document.getElementById("revenueChart").getContext("2d");
    this.chart = new Chart(ctx, {
      type: "line",
      data: {
        datasets: [this.revenueDataset, this.salesDataset]
      },
      options: {
        animation: { duration: 100 },
        title: {
          text: "Real-time statistics   (agent is not shown)",
          display: true
        },
        legend: { position: "bottom" },
        tooltips: { enabled: false },
        scales: {
          xAxes: [
            {
              type: "time",
              display: false
            }
          ],
          yAxes: [
            {
              id: "revenue-axis",
              position: "left",
              type: "linear",
              display: true,
              ticks: {},
              scaleLabel: {
                display: true,
                labelString: "Your Revenue",
                fontColor: this.rgbaRevenue(1)
              }
            },
            {
              id: "sales-axis",
              position: "right",
              type: "linear",
              display: true,
              ticks: {},
              gridLines: { display: false },
              scaleLabel: {
                display: true,
                labelString: "Sold Items",
                fontColor: this.rgbaSales(1)
              }
            }
          ]
        }
      }
    });
    this.updateAxes();
    this.chart.update();
  }

  loadDemo() {
    if (this.savedData === null) {
      this.savedData = {
        revenue: this.revenueDataset.data,
        sales: this.salesDataset.data
      };
      this.revenueDataset.data = demoData.revenue;
      this.salesDataset.data = demoData.sales;
    }
  }

  unloadDemo() {
    if (this.savedData !== null) {
      this.revenueDataset.data = this.savedData.revenue;
      this.salesDataset.data = this.savedData.sales;
      this.savedData = null;
    }
  }
  updateAxes() {
    // Initial y-axes limit
    this.chart.options.scales.yAxes[0].ticks.suggestedMax = 100; // revenue dataset
    this.chart.options.scales.yAxes[1].ticks.suggestedMax = 10; // sales dataset
    this.chart.options.scales.yAxes[0].ticks.suggestedMin = -100; // revenue dataset
    this.chart.options.scales.yAxes[1].ticks.suggestedMin = -10; // sales dataset
  }

  render() {
    const { showDemo, playerSales, playerRevenue } = this.props;
    const maxPoints = 80;
    const movingAveragePoints = 3;
    // first render() is before componentDidMount()
    if (this.chart !== null) {
      if (showDemo) {
        this.loadDemo();
      } else {
        this.unloadDemo();
        this.revenueDataset.data = this.movingAvg(
          playerRevenue,
          movingAveragePoints
        ).slice(-maxPoints);
        this.salesDataset.data = this.movingAvg(
          playerSales,
          movingAveragePoints
        ).slice(-maxPoints);
      }

      if (playerRevenue.length === 0) {
        this.updateAxes();
      }

      // Adjust y-axes to maximum and minimum Y value to avoid resizing down
      const findMaxY = (prev, v) => (v.y > prev ? v.y : prev);
      const findMinY = (prev, v) => (v.y < prev ? v.y : prev);
      const maxRevenue = this.revenueDataset.data.reduce(findMaxY, 0);
      const maxSales = this.salesDataset.data.reduce(findMaxY, 0);
      const minRevenue = this.revenueDataset.data.reduce(findMinY, 0);
      const minSales = this.salesDataset.data.reduce(findMinY, 0);
      if (this.chart.options.scales.yAxes[0].ticks.suggestedMax < maxRevenue) {
        // revenue max Y
        this.chart.options.scales.yAxes[0].ticks.suggestedMax =
          maxRevenue - (maxRevenue % 100) + 100; // nearest greater 100 multiple
      }
      if (this.chart.options.scales.yAxes[1].ticks.suggestedMax < maxSales) {
        // sales max Y
        this.chart.options.scales.yAxes[1].ticks.suggestedMax =
          maxSales - (maxSales % 10) + 10; // nearest greater 10 multiple
      }
      if (this.chart.options.scales.yAxes[0].ticks.suggestedMin > minRevenue) {
        // revenue min Y
        this.chart.options.scales.yAxes[0].ticks.suggestedMin =
          minRevenue - (minRevenue % 100) - 100; // nearest greater 100 multiple
      }
      if (this.chart.options.scales.yAxes[1].ticks.suggestedMin > minSales) {
        // sales min Y
        this.chart.options.scales.yAxes[1].ticks.suggestedMin =
          minSales - (minSales % 10) - 10; // nearest greater 10 multiple
      }

      // disable animation after the screen is filled because points jump vertically
      if (
        this.chart.options.animation.duration &&
        playerSales.length > maxPoints
      ) {
        this.chart.options.animation.duration = 0;
      }

      this.chart.update();
    }

    return <canvas id="revenueChart" width="400" height="180" />;
  }
}

LineChart.propTypes = {
  playerSales: PropTypes.array,
  playerRevenue: PropTypes.array
};

export default LineChart;
