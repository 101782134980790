import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import BarChart from "./BarChart.js";
import LineChart from "./LineChart.js";
import Grow from "@material-ui/core/Grow";
import Divider from "@material-ui/core/Divider";

import winnerImg from "./img/winner.svg";
import loserImg from "./img/loser.svg";

const styles = theme => ({
  infoPaper: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    margin: "auto",
    textAlign: "center",
    background: "rgba(150, 150, 150, 0.2)"
  },
  button: {
    margin: theme.spacing.unit / 4,
    width: 45,
    minWidth: 45
  },
  blueText: {
    fontWeight: "bold",
    color: theme.palette.primary.main
  },
  redText: {
    fontWeight: "bold",
    color: theme.palette.secondary.main
  },
  boardTitle: {
    marginBottom: theme.spacing.unit * 3
  },
  textField: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    "& input": {
      textAlign: "center"
    }
  },
  costTextField: { width: 120, fontWeight: "bold" },
  profitText: { fontWeight: "bold" },
  profitCalculationText: { width: 120, marginRight: -55, fontSize: "0.8em" },
  nextCostCircle: {
    position: "relative",
    left: 10
  },
  nextCostText: {
    position: "relative",
    left: 10,
    fontSize: "1em",
    marginRight: 10,
    animationDuration: "1000ms"
  },
  currentCostInput: {
    // color: theme.palette.primary.main
  },
  costTextAlertOn: {
    "& legend": {},
    "& fieldset": {
      borderColor: `${theme.palette.secondary.main} !important`
    }
  },
  costTextAlertOnLabel: {
    color: theme.palette.secondary.main
  },
  costTextAlertOff: {}
});

class FinalMessage extends React.Component {
  render() {
    const { tutorialClassName, classes, gameState, players } = this.props;

    const pRev = gameState.player.totalRevenue;
    const aRev = gameState.agent.totalRevenue;

    let robotCup = aRev >= 1.01 * pRev; // More than 1% advantage for agent
    let diffPercentage = Math.round(
      (100 * Math.abs(pRev - aRev)) / Math.min(pRev, aRev)
    );
    let optimalRevenue = players.optimal.agent.totalRevenue();
    let playerEfficiency = Math.round((100 * pRev) / optimalRevenue);
    let agentEfficiency = Math.round((100 * aRev) / optimalRevenue);
    let mainMessage;
    let subMessage;
    let explainMessage = "";
    if (diffPercentage < 1) {
      mainMessage = "This can be considered a tie!";
      subMessage = (
        <Typography variant="h6" gutterBottom>
          The difference of revenue between you and our agent was less than 1%!
        </Typography>
      );
      explainMessage = (
        <Typography variant="body1">
          Keep training and you may defeat it, since it learns from scratch
          every time, while you gain experience in each attempt.
          <br />
          It's hard to beat anyway because it's quite efficient after it learns
          in the initial exploration.
        </Typography>
      );
    } else if (pRev > aRev) {
      mainMessage = "Congratulations! You won!";
      subMessage = (
        <Typography variant="h6" gutterBottom>
          You achieved <b>{diffPercentage}%</b> more than our agent
        </Typography>
      );
      explainMessage = (
        <Typography variant="body1">
          As you gain some experience by playing a couple times, you'll probably
          defeat our agent, because it's <b>not pre-trained</b>. It learns from
          scratch everytime.
          <br />
          Could you do it for the other type of products?
        </Typography>
      );
    } else {
      mainMessage =
        diffPercentage < 5 ? "You were so close!" : "Our agent won...";
      subMessage = (
        <Typography variant="h6" gutterBottom>
          Our agent achieved <b>{diffPercentage}%</b> more than you
        </Typography>
      );
      explainMessage = (
        <Typography variant="body1">
          Keep trying! Look out for cost changes! Your profit could drop to
          negative values if the cost goes too high.
        </Typography>
      );
    }

    return (
      <Grow in={true}>
        <div className={tutorialClassName}>
          <Grid container spacing={24} alignItems="center" justify="center">
            <Grid item xs={3}>
              {robotCup ? (
                <img
                  src={loserImg}
                  alt="Robot arm grabbing the winner trophy"
                />
              ) : (
                <img src={winnerImg} alt="Winner trophy" />
              )}
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h4" gutterBottom color={"primary"}>
                {mainMessage}
              </Typography>
              {subMessage}
              {explainMessage}
            </Grid>
            <Grid item xs={12}>
              <BarChart
                playerTotalRevenue={gameState.player.totalRevenue}
                agentTotalRevenue={gameState.agent.totalRevenue}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <Typography variant="body1">
                <b>Performances: </b>
                {"   "}
                <span className={classes.blueText}>
                  You: <b>{playerEfficiency}%</b>
                </span>
                {"   "}|{"   "}
                <span className={classes.redText}>
                  Agent: <b>{agentEfficiency}%</b>
                </span>
                {"  "} compared to the <b>optimal</b> revenue
              </Typography>

              <Typography
                variant="body1"
                className={classes.gameHistoryMessage}
              >
                The optimal revenue is calculated using all the market
                parameters, which are unknown to you and the agent.
                <br />
                Check the <b>Game History</b> below and compare yourself with
                the optimal price and the agent's.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Grow>
    );
  }
}

class NumericValues extends React.Component {
  alertMargin = 0.1;
  alertProgress = 0;

  // Cost change alert
  alertState(gameState) {
    let endAlert = gameState.nextCostUpdateProgress;
    this.alertProgress =
      1.0 - (endAlert - gameState.gameProgress) / this.alertMargin;

    // Blinking alarm: 1 shows text, 0 hides text, -1 for alarm (timer) OFF
    if (this.alertProgress >= 0) {
      return this.alertProgress < 0.2 ||
        (this.alertProgress > 0.3 && this.alertProgress < 0.6) ||
        this.alertProgress > 0.7
        ? 1
        : 0;
    } else {
      return -1;
    }
  }

  render() {
    const { classes, gameState } = this.props;
    var alertState;
    var productCost;
    var nextProductCost;
    var selectedPrice;
    if (gameState.tourState.visible) {
      alertState = gameState.tourState.showCostChange ? 1 : -1;
      this.alertProgress = 0.7; // ignored if alertState == -1
      productCost = 13;
      nextProductCost = 15;
      selectedPrice = 21;
    } else {
      alertState = this.alertState(gameState);
      productCost = gameState.productCost;
      nextProductCost = gameState.nextProductCost;
      selectedPrice = gameState.player.selectedPrice;
    }
    return (
      <div>
        <TextField
          id="product_cost"
          label="Cost (per item)"
          className={classNames(
            classes.textField,
            classes.costTextField,
            alertState === -1
              ? ""
              : alertState === 1
              ? classes.costTextAlertOn
              : classes.costTextAlertOff
          )}
          margin="dense"
          InputLabelProps={{
            className: alertState === 1 ? classes.costTextAlertOnLabel : ""
          }}
          InputProps={{
            readOnly: true,
            className: classes.currentCostInput,
            startAdornment:
              alertState >= 0 ? (
                <React.Fragment>
                  <CircularProgress
                    className={classes.nextCostCircle}
                    variant="static"
                    color="secondary"
                    value={
                      // workaround for animation delay
                      5 * parseInt((100 / 5) * (1 - this.alertProgress))
                    }
                  />
                  <Typography
                    className={classes.nextCostText}
                    color="secondary"
                    style={{ opacity: alertState }}
                  >
                    {nextProductCost}
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment />
              )
          }}
          variant="outlined"
          value={productCost}
        />
        <TextField
          id="profit_margin"
          label="Your Price - Cost = Profit"
          className={classes.textField}
          margin="dense"
          InputProps={{
            readOnly: true,
            className: classes.profitText,
            startAdornment: (
              <Typography className={classes.profitCalculationText}>
                {selectedPrice} - {productCost} =
              </Typography>
            )
          }}
          variant="outlined"
          value={selectedPrice - productCost}
        />
      </div>
    );
  }
}

class InfoBoard extends React.Component {
  render() {
    const {
      tutorialClassNames,
      classes,
      gameState,
      players,
      finishedGame
    } = this.props;

    return (
      <React.Fragment>
        <div className={tutorialClassNames.infoBoard}>
          <Paper className={classes.infoPaper}>
            {!finishedGame || gameState.tourState.visible ? (
              <Grid container spacing={24} alignItems="center">
                <Grid item xs={8} className={tutorialClassNames.revenuePlot}>
                  <LineChart
                    showDemo={gameState.tourState.visible}
                    playerSales={gameState.player.salesHistory}
                    playerRevenue={gameState.player.rewardHistory}
                  />
                </Grid>
                <Grid item xs={4} className={tutorialClassNames.numericValues}>
                  <NumericValues
                    tutorialClassNames={tutorialClassNames}
                    classes={classes}
                    gameState={gameState}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={tutorialClassNames.competitionBar}
                >
                  <BarChart
                    showDemo={gameState.tourState.visible}
                    playerTotalRevenue={gameState.player.totalRevenue}
                    agentTotalRevenue={gameState.agent.totalRevenue}
                  />
                </Grid>
              </Grid>
            ) : (
              <Zoom in={true}>
                <FinalMessage
                  classes={classes}
                  gameState={gameState}
                  players={players}
                />
              </Zoom>
            )}
          </Paper>
        </div>
      </React.Fragment>
    );
  }
}

InfoBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  gameState: PropTypes.object.isRequired,
  players: PropTypes.object
};

export default withStyles(styles)(InfoBoard);
