import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { PlayerBoard, AgentBoard } from "./Boards.js";
import InfoBoard from "./InfoBoard.js";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import HelpIcon from "@material-ui/icons/Help";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import ReplayIcon from "@material-ui/icons/Replay";
import FastForwardIcon from "@material-ui/icons/FastForward";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Tooltip from "@material-ui/core/Tooltip";

import GameProgressBar from "./GameProgressBar.js";
import FinalStatistics from "./FinalStatistics.js";
import ProductSelection from "./ProductSelection.js";
import Tutorial from "./Tutorial.js";

import logoTryolabs from "./img/logoTryolabs.svg";

const styles = theme => ({
  root: { flexGrow: 1 },
  gameBoardContainer: {
    position: "relative"
  },
  gameOverlay: {
    position: "absolute",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    top: "-2.5%",
    left: "-2.5%",
    width: "105%",
    height: "102.5%",
    textAlign: "center",
    zIndex: 99999
  },
  startButton: {
    top: "50%"
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
    color: "#6d6e71",
    marginTop: "20px"
  },
  title: {
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "10px"
  },
  inlineLogo: {
    height: "1.3em",
    marginBottom: "-0.35em"
  },
  externalLink: {
    color: "#99CD00",
    textDecoration: "none",
    fontWeight: "bold"
  },
  paper: {
    width: 800,
    padding: theme.spacing.unit * 3,
    margin: "auto",
    marginTop: theme.spacing.unit * 5
  },
  speedToggle: { margin: "auto", marginTop: "18px" },
  alignCenterGrid: { textAlign: "center" },
  centeredContentGrid: { margin: "auto" },
  leftContentGrid: { marginLeft: 0 },
  absPosGrid: { position: "relative" },
  rightDiv: { position: "absolute", right: 5, top: 21 },
  button: {
    margin: theme.spacing.unit
  },
  tutorialImg: {
    width: "100%"
  },
  blueText: {
    fontWeight: "bold",
    color: theme.palette.primary.main
  },
  redText: {
    fontWeight: "bold",
    color: theme.palette.secondary.main
  },
  greyText: {
    fontWeight: "bold",
    color: "rgba(150, 150, 150, 1)"
  },
  tutorialTitle: {
    color: theme.palette.primary.main
  },
  helpButton: {
    backgroundColor: "transparent",
    boxShadow: "unset",
    width: "36px",
    height: "36px",
    color: theme.palette.primary.main
  },
  gameBoard: {
    marginTop: "20px"
  },
  productSelection: {},
  priceSelector: {},
  agentPricePanel: {},
  infoBoardPanel: {},
  revenuePlotPanel: {},
  numericValuesPanel: {},
  competitionBarPanel: {},
  progressBarPanel: {},
  finalStatisticsPanel: {}
});

class Overlay extends React.Component {
  render() {
    const { classes, actions } = this.props;

    return (
      <div className={classes.gameOverlay}>
        <Button
          color="primary"
          variant="contained"
          size="large"
          className={classes.startButton}
          onClick={actions.disableOverlay}
        >
          Start tutorial
        </Button>
      </div>
    );
  }
}

class MainWindow extends React.Component {
  render() {
    const {
      classes,
      gameState,
      actions,
      gameTurns,
      playersComparison
    } = this.props;

    const finishedGame = gameState.nTurns === gameTurns;

    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <ExpansionPanel
              expanded={gameState.showDescription}
              onChange={actions.toggleDescription}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.titlePanel}
              >
                <Typography
                  color="primary"
                  variant="h4"
                  className={classes.title}
                >
                  Can you beat this pricing algorithm?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography variant="h6">
                  Welcome to our pricing game! We are <b>Tryolabs</b>, a Machine
                  Learning and Data Science consulting firm and we love to stay
                  up to date with the latest technologies.
                  <br />
                  <br />
                  This time we wanted a playground to try out some simple{" "}
                  <b>Reinforcement Learning</b> algorithms for{" "}
                  <b>Dynamic Pricing Optimization</b>, so we created this game.
                  But it still needs some introduction to make sense out of it,
                  so...
                  <br />
                  <br />
                  <div className={classes.alignCenterGrid}>
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      href="https://www.tryolabs.com/blog/2019/05/14/can-you-beat-this-pricing-algorithm/"
                    >
                      Go to the blogpost
                    </Button>
                    <b>or</b>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={actions.disableOverlay}
                    >
                      Start tutorial
                    </Button>
                  </div>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <div className={classes.gameBoardContainer}>
              {gameState.firstVisit ? (
                <Overlay classes={classes} actions={actions} />
              ) : (
                <React.Fragment />
              )}
              <GameBoard
                classes={classes}
                gameState={gameState}
                actions={actions}
                playersComparison={playersComparison}
                finishedGame={finishedGame}
              />
            </div>
            <div className={classes.footer}>
              Created by{" "}
              <a href="https://tryolabs.com/">
                <img src={logoTryolabs} alt="Tryolabs logo" width="100px" />
              </a>
            </div>
          </Paper>
        </div>
      </React.Fragment>
    );
  }
}

class GameBoard extends React.Component {
  playerBoard = null; // reference to player board node

  render() {
    const {
      classes,
      gameState,
      actions,
      playersComparison,
      finishedGame
    } = this.props;

    const showFinalStatisticsDemo =
      gameState.tourState.visible && gameState.tourState.showFinalStatistics;

    return (
      <div>
        <Tutorial
          classes={classes}
          isOpen={gameState.tourState.visible}
          actions={actions}
        />
        <Grid container spacing={24} className={classes.gameBoard}>
          <Grid item xs={8} className={classes.leftContentGrid}>
            <ProductSelection
              gameState={gameState}
              actions={actions}
              tutorialClassName={classes.productSelection}
            />
          </Grid>
          <Grid item xs={4} className={classes.absPosGrid}>
            <div className={classes.rightDiv}>
              <Tooltip
                title={
                  <span>
                    Toggle game speed
                    <br />
                    Current: {gameState.highSpeed ? "High" : "Normal"}{" "}
                  </span>
                }
              >
                <Fab
                  variant="extended"
                  size="small"
                  color={gameState.highSpeed ? "secondary" : "primary"}
                  className={classes.button}
                  onClick={actions.toggleSpeed}
                >
                  <FastForwardIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Reset game">
                <Fab
                  size="large"
                  className={classes.button}
                  color="secondary"
                  disabled={gameState.gameTimeout !== null}
                  onClick={actions.restartGame}
                >
                  <ReplayIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Play/Pause">
                <Fab
                  size="large"
                  className={classes.button}
                  color="primary"
                  onClick={actions.togglePlay}
                  disabled={finishedGame}
                >
                  {gameState.gameTimeout === null ? (
                    <PlayArrowIcon />
                  ) : (
                    <PauseIcon />
                  )}
                </Fab>
              </Tooltip>
              <Tooltip title="Show tutorial">
                <Fab className={classes.helpButton} onClick={actions.openTour}>
                  <HelpIcon />
                </Fab>
              </Tooltip>
            </div>
          </Grid>
          <Grid item xs={6}>
            <PlayerBoard
              tutorialClassName={classes.priceSelector}
              innerRef={node => (this.playerBoard = node)}
              gameState={gameState}
              actions={actions}
            />
          </Grid>
          <Grid item xs={6}>
            <AgentBoard
              tutorialClassName={classes.agentPricePanel}
              gameState={gameState}
              actions={actions}
            />
          </Grid>
          {!showFinalStatisticsDemo ? (
            <Grid item xs={12} className={classes.alignCenterGrid}>
              <InfoBoard
                tutorialClassNames={{
                  infoBoard: classes.infoBoardPanel,
                  revenuePlot: classes.revenuePlotPanel,
                  numericValues: classes.numericValuesPanel,
                  competitionBar: classes.competitionBarPanel
                }}
                gameState={gameState}
                players={playersComparison}
                finishedGame={finishedGame}
              />
            </Grid>
          ) : (
            <React.Fragment />
          )}
          {finishedGame || showFinalStatisticsDemo ? (
            <Grid item xs={12} className={classes.alignCenterGrid}>
              <FinalStatistics
                tutorialClassName={classes.finalStatisticsPanel}
                tourState={gameState.tourState}
                players={playersComparison}
              />
            </Grid>
          ) : (
            <Grid item xs={12} className={classes.alignCenterGrid}>
              <GameProgressBar
                showDemo={gameState.tourState.visible}
                tutorialClassName={classes.progressBarPanel}
                gameProgress={gameState.gameProgress}
              />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

GameBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  gameState: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  playersComparison: PropTypes.object
};

MainWindow.propTypes = {
  classes: PropTypes.object.isRequired,
  gameState: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  gameTurns: PropTypes.number.isRequired,
  playersComparison: PropTypes.object
};

export default withStyles(styles)(MainWindow);
