const salesData = [
  {
    "t": 25,
    "y": 1981.312495900355
  },
  {
    "t": 26,
    "y": 2063.550104372395
  },
  {
    "t": 27,
    "y": 2052.154338462588
  },
  {
    "t": 28,
    "y": 1962.211615363144
  },
  {
    "t": 29,
    "y": 1872.56448229692
  },
  {
    "t": 30,
    "y": 1869.9224283718656
  },
  {
    "t": 31,
    "y": 1917.6046415415306
  },
  {
    "t": 32,
    "y": 1951.0175831650643
  },
  {
    "t": 33,
    "y": 1907.2920201244397
  },
  {
    "t": 34,
    "y": 1877.0416134659217
  },
  {
    "t": 35,
    "y": 1785.3755655591906
  },
  {
    "t": 36,
    "y": 1778.392328974642
  },
  {
    "t": 37,
    "y": 1700.0279306708007
  },
  {
    "t": 38,
    "y": 1693.0284891811561
  },
  {
    "t": 39,
    "y": 1606.1175683786805
  },
  {
    "t": 40,
    "y": 1623.0670378976045
  },
  {
    "t": 41,
    "y": 1543.3885996705783
  },
  {
    "t": 42,
    "y": 1544.753090322273
  },
  {
    "t": 43,
    "y": 1476.4578365399664
  },
  {
    "t": 44,
    "y": 1500.1543571838774
  },
  {
    "t": 45,
    "y": 1505.1629625647172
  },
  {
    "t": 46,
    "y": 1488.0648493618392
  },
  {
    "t": 47,
    "y": 1496.3520617187376
  },
  {
    "t": 48,
    "y": 1472.1971893756645
  },
  {
    "t": 49,
    "y": 1530.8903362071596
  },
  {
    "t": 50,
    "y": 1484.004706976107
  },
  {
    "t": 51,
    "y": 1459.5826210086682
  },
  {
    "t": 52,
    "y": 1389.3392845406922
  },
  {
    "t": 53,
    "y": 1408.09861552875
  },
  {
    "t": 54,
    "y": 1352.9225514070856
  },
  {
    "t": 55,
    "y": 1354.4255579821822
  },
  {
    "t": 56,
    "y": 1391.5235289435143
  },
  {
    "t": 57,
    "y": 1363.9425018229501
  },
  {
    "t": 58,
    "y": 1347.528029385151
  },
  {
    "t": 59,
    "y": 1311.049137833266
  },
  {
    "t": 60,
    "y": 1318.8812355349137
  },
  {
    "t": 61,
    "y": 1273.2914658513353
  },
  {
    "t": 62,
    "y": 1237.3585636522196
  },
  {
    "t": 63,
    "y": 1259.0563765831264
  },
  {
    "t": 64,
    "y": 1276.7569069816225
  },
  {
    "t": 65,
    "y": 1269.722148860428
  },
  {
    "t": 66,
    "y": 1212.6746405601546
  },
  {
    "t": 67,
    "y": 1173.6455732083787
  },
  {
    "t": 68,
    "y": 1132.3935915447562
  },
  {
    "t": 69,
    "y": 1146.4110477394845
  },
  {
    "t": 70,
    "y": 1110.1564236277661
  },
  {
    "t": 71,
    "y": 1136.4267427541097
  },
  {
    "t": 72,
    "y": 1201.627458949982
  },
  {
    "t": 73,
    "y": 1303.468075270569
  },
  {
    "t": 74,
    "y": 1313.754963571888
  },
  {
    "t": 75,
    "y": 1205.7534186491496
  },
  {
    "t": 76,
    "y": 1119.5607897489037
  },
  {
    "t": 77,
    "y": 863.4024626565109
  },
  {
    "t": 78,
    "y": 734.3121840348898
  },
  {
    "t": 79,
    "y": 524.6827793800595
  },
  {
    "t": 80,
    "y": 565.5336722586917
  },
  {
    "t": 81,
    "y": 474.5487727670073
  },
  {
    "t": 82,
    "y": 499.72372015126456
  },
  {
    "t": 83,
    "y": 429.4233120030029
  },
  {
    "t": 84,
    "y": 491.4419953967099
  },
  {
    "t": 85,
    "y": 500.54884152415616
  },
  {
    "t": 86,
    "y": 567.2321356295389
  },
  {
    "t": 87,
    "y": 579.6979391194981
  },
  {
    "t": 88,
    "y": 587.8173733204816
  },
  {
    "t": 89,
    "y": 625.7906316971292
  },
  {
    "t": 90,
    "y": 680.6974307516164
  },
  {
    "t": 91,
    "y": 738.3018133946725
  },
  {
    "t": 92,
    "y": 730.7196904699662
  },
  {
    "t": 93,
    "y": 692.1810245402748
  },
  {
    "t": 94,
    "y": 643.201953468928
  },
  {
    "t": 95,
    "y": 671.3651164896705
  },
  {
    "t": 96,
    "y": 728.4597348429701
  },
  {
    "t": 97,
    "y": 788.9923638784645
  },
  {
    "t": 98,
    "y": 744.9994455067705
  },
  {
    "t": 99,
    "y": 715.80557370934
  },
  {
    "t": 100,
    "y": 709.9010140390716
  },
  {
    "t": 101,
    "y": 764.4528295245391
  },
  {
    "t": 102,
    "y": 850.5171836372492
  },
  {
    "t": 103,
    "y": 912.0840787826037
  },
  {
    "t": 104,
    "y": 904.7342737474877
  }
]

const revenueData = [
  {
    "t": 25,
    "y": 1981.312495900355
  },
  {
    "t": 26,
    "y": 2063.550104372395
  },
  {
    "t": 27,
    "y": 2052.154338462588
  },
  {
    "t": 28,
    "y": 1962.211615363144
  },
  {
    "t": 29,
    "y": 1872.56448229692
  },
  {
    "t": 30,
    "y": 1869.9224283718656
  },
  {
    "t": 31,
    "y": 1917.6046415415306
  },
  {
    "t": 32,
    "y": 1951.0175831650643
  },
  {
    "t": 33,
    "y": 1907.2920201244397
  },
  {
    "t": 34,
    "y": 1877.0416134659217
  },
  {
    "t": 35,
    "y": 1785.3755655591906
  },
  {
    "t": 36,
    "y": 1778.392328974642
  },
  {
    "t": 37,
    "y": 1700.0279306708007
  },
  {
    "t": 38,
    "y": 1693.0284891811561
  },
  {
    "t": 39,
    "y": 1606.1175683786805
  },
  {
    "t": 40,
    "y": 1623.0670378976045
  },
  {
    "t": 41,
    "y": 1543.3885996705783
  },
  {
    "t": 42,
    "y": 1544.753090322273
  },
  {
    "t": 43,
    "y": 1476.4578365399664
  },
  {
    "t": 44,
    "y": 1500.1543571838774
  },
  {
    "t": 45,
    "y": 1505.1629625647172
  },
  {
    "t": 46,
    "y": 1488.0648493618392
  },
  {
    "t": 47,
    "y": 1496.3520617187376
  },
  {
    "t": 48,
    "y": 1472.1971893756645
  },
  {
    "t": 49,
    "y": 1530.8903362071596
  },
  {
    "t": 50,
    "y": 1484.004706976107
  },
  {
    "t": 51,
    "y": 1459.5826210086682
  },
  {
    "t": 52,
    "y": 1389.3392845406922
  },
  {
    "t": 53,
    "y": 1408.09861552875
  },
  {
    "t": 54,
    "y": 1352.9225514070856
  },
  {
    "t": 55,
    "y": 1354.4255579821822
  },
  {
    "t": 56,
    "y": 1391.5235289435143
  },
  {
    "t": 57,
    "y": 1363.9425018229501
  },
  {
    "t": 58,
    "y": 1347.528029385151
  },
  {
    "t": 59,
    "y": 1311.049137833266
  },
  {
    "t": 60,
    "y": 1318.8812355349137
  },
  {
    "t": 61,
    "y": 1273.2914658513353
  },
  {
    "t": 62,
    "y": -72.47664091771215
  },
  {
    "t": 63,
    "y": -1321.2216958802103
  },
  {
    "t": 64,
    "y": -2553.5138139632477
  },
  {
    "t": 65,
    "y": -2539.4442977208587
  },
  {
    "t": 66,
    "y": -2425.349281120312
  },
  {
    "t": 67,
    "y": -2347.2911464167596
  },
  {
    "t": 68,
    "y": -2264.7871830895147
  },
  {
    "t": 69,
    "y": -2292.8220954789717
  },
  {
    "t": 70,
    "y": -2220.3128472555345
  },
  {
    "t": 71,
    "y": -2272.8534855082216
  },
  {
    "t": 72,
    "y": -2403.254917899966
  },
  {
    "t": 73,
    "y": -2606.9361505411403
  },
  {
    "t": 74,
    "y": -2627.509927143779
  },
  {
    "t": 75,
    "y": -2411.5068372983014
  },
  {
    "t": 76,
    "y": -2239.12157949781
  },
  {
    "t": 77,
    "y": -714.4616728704441
  },
  {
    "t": 78,
    "y": 795.077663435413
  },
  {
    "t": 79,
    "y": 2098.73111752024
  },
  {
    "t": 80,
    "y": 2262.134689034769
  },
  {
    "t": 81,
    "y": 1898.1950910680316
  },
  {
    "t": 82,
    "y": 1998.8948806050605
  },
  {
    "t": 83,
    "y": 1717.693248012014
  },
  {
    "t": 84,
    "y": 1965.767981586842
  },
  {
    "t": 85,
    "y": 2002.1953660966271
  },
  {
    "t": 86,
    "y": 2268.9285425181583
  },
  {
    "t": 87,
    "y": 2318.7917564779946
  },
  {
    "t": 88,
    "y": 2351.269493281929
  },
  {
    "t": 89,
    "y": 2503.1625267885192
  },
  {
    "t": 90,
    "y": 2722.7897230064677
  },
  {
    "t": 91,
    "y": 2953.2072535786924
  },
  {
    "t": 92,
    "y": 2922.878761879867
  },
  {
    "t": 93,
    "y": 2768.7240981611017
  },
  {
    "t": 94,
    "y": 2572.807813875714
  },
  {
    "t": 95,
    "y": 2685.4604659586844
  },
  {
    "t": 96,
    "y": 2913.8389393718826
  },
  {
    "t": 97,
    "y": 3155.969455513861
  },
  {
    "t": 98,
    "y": 2979.9977820270847
  },
  {
    "t": 99,
    "y": 2863.2222948373624
  },
  {
    "t": 100,
    "y": 2839.604056156289
  },
  {
    "t": 101,
    "y": 3057.8113180981586
  },
  {
    "t": 102,
    "y": 3402.068734548999
  },
  {
    "t": 103,
    "y": 3648.336315130417
  },
  {
    "t": 104,
    "y": 3618.937094989953
  }
]

const finalStatisticsDemoPlayers = [
  {
    "name": "Optimal",
    "agent": {
      "prices": [
        15,
        16,
        18,
        21,
        25,
        30
      ],
      "nPrices": 6,
      "pricesIdx": [
        0,
        1,
        2,
        3,
        4,
        5
      ],
      "turn": 200,
      "priceHistory": [
        {
          "t": 0,
          "y": 15
        },
        {
          "t": 1,
          "y": 21
        },
        {
          "t": 2,
          "y": 21
        },
        {
          "t": 3,
          "y": 21
        },
        {
          "t": 4,
          "y": 21
        },
        {
          "t": 5,
          "y": 21
        },
        {
          "t": 6,
          "y": 21
        },
        {
          "t": 7,
          "y": 21
        },
        {
          "t": 8,
          "y": 21
        },
        {
          "t": 9,
          "y": 21
        },
        {
          "t": 10,
          "y": 21
        },
        {
          "t": 11,
          "y": 21
        },
        {
          "t": 12,
          "y": 21
        },
        {
          "t": 13,
          "y": 21
        },
        {
          "t": 14,
          "y": 21
        },
        {
          "t": 15,
          "y": 21
        },
        {
          "t": 16,
          "y": 21
        },
        {
          "t": 17,
          "y": 21
        },
        {
          "t": 18,
          "y": 21
        },
        {
          "t": 19,
          "y": 21
        },
        {
          "t": 20,
          "y": 21
        },
        {
          "t": 21,
          "y": 21
        },
        {
          "t": 22,
          "y": 21
        },
        {
          "t": 23,
          "y": 21
        },
        {
          "t": 24,
          "y": 21
        },
        {
          "t": 25,
          "y": 21
        },
        {
          "t": 26,
          "y": 21
        },
        {
          "t": 27,
          "y": 21
        },
        {
          "t": 28,
          "y": 21
        },
        {
          "t": 29,
          "y": 21
        },
        {
          "t": 30,
          "y": 21
        },
        {
          "t": 31,
          "y": 21
        },
        {
          "t": 32,
          "y": 21
        },
        {
          "t": 33,
          "y": 21
        },
        {
          "t": 34,
          "y": 21
        },
        {
          "t": 35,
          "y": 21
        },
        {
          "t": 36,
          "y": 21
        },
        {
          "t": 37,
          "y": 21
        },
        {
          "t": 38,
          "y": 21
        },
        {
          "t": 39,
          "y": 21
        },
        {
          "t": 40,
          "y": 21
        },
        {
          "t": 41,
          "y": 21
        },
        {
          "t": 42,
          "y": 21
        },
        {
          "t": 43,
          "y": 21
        },
        {
          "t": 44,
          "y": 21
        },
        {
          "t": 45,
          "y": 21
        },
        {
          "t": 46,
          "y": 21
        },
        {
          "t": 47,
          "y": 21
        },
        {
          "t": 48,
          "y": 21
        },
        {
          "t": 49,
          "y": 21
        },
        {
          "t": 50,
          "y": 21
        },
        {
          "t": 51,
          "y": 21
        },
        {
          "t": 52,
          "y": 21
        },
        {
          "t": 53,
          "y": 21
        },
        {
          "t": 54,
          "y": 21
        },
        {
          "t": 55,
          "y": 21
        },
        {
          "t": 56,
          "y": 21
        },
        {
          "t": 57,
          "y": 21
        },
        {
          "t": 58,
          "y": 21
        },
        {
          "t": 59,
          "y": 21
        },
        {
          "t": 60,
          "y": 21
        },
        {
          "t": 61,
          "y": 21
        },
        {
          "t": 62,
          "y": 21
        },
        {
          "t": 63,
          "y": 18
        },
        {
          "t": 64,
          "y": 18
        },
        {
          "t": 65,
          "y": 18
        },
        {
          "t": 66,
          "y": 18
        },
        {
          "t": 67,
          "y": 18
        },
        {
          "t": 68,
          "y": 18
        },
        {
          "t": 69,
          "y": 18
        },
        {
          "t": 70,
          "y": 18
        },
        {
          "t": 71,
          "y": 18
        },
        {
          "t": 72,
          "y": 18
        },
        {
          "t": 73,
          "y": 18
        },
        {
          "t": 74,
          "y": 18
        },
        {
          "t": 75,
          "y": 18
        },
        {
          "t": 76,
          "y": 18
        },
        {
          "t": 77,
          "y": 18
        },
        {
          "t": 78,
          "y": 18
        },
        {
          "t": 79,
          "y": 18
        },
        {
          "t": 80,
          "y": 18
        },
        {
          "t": 81,
          "y": 18
        },
        {
          "t": 82,
          "y": 18
        },
        {
          "t": 83,
          "y": 18
        },
        {
          "t": 84,
          "y": 18
        },
        {
          "t": 85,
          "y": 18
        },
        {
          "t": 86,
          "y": 18
        },
        {
          "t": 87,
          "y": 18
        },
        {
          "t": 88,
          "y": 18
        },
        {
          "t": 89,
          "y": 18
        },
        {
          "t": 90,
          "y": 18
        },
        {
          "t": 91,
          "y": 18
        },
        {
          "t": 92,
          "y": 18
        },
        {
          "t": 93,
          "y": 18
        },
        {
          "t": 94,
          "y": 18
        },
        {
          "t": 95,
          "y": 18
        },
        {
          "t": 96,
          "y": 18
        },
        {
          "t": 97,
          "y": 18
        },
        {
          "t": 98,
          "y": 18
        },
        {
          "t": 99,
          "y": 18
        },
        {
          "t": 100,
          "y": 18
        },
        {
          "t": 101,
          "y": 18
        },
        {
          "t": 102,
          "y": 18
        },
        {
          "t": 103,
          "y": 18
        },
        {
          "t": 104,
          "y": 18
        },
        {
          "t": 105,
          "y": 18
        },
        {
          "t": 106,
          "y": 18
        },
        {
          "t": 107,
          "y": 18
        },
        {
          "t": 108,
          "y": 18
        },
        {
          "t": 109,
          "y": 18
        },
        {
          "t": 110,
          "y": 18
        },
        {
          "t": 111,
          "y": 18
        },
        {
          "t": 112,
          "y": 18
        },
        {
          "t": 113,
          "y": 18
        },
        {
          "t": 114,
          "y": 18
        },
        {
          "t": 115,
          "y": 18
        },
        {
          "t": 116,
          "y": 18
        },
        {
          "t": 117,
          "y": 18
        },
        {
          "t": 118,
          "y": 18
        },
        {
          "t": 119,
          "y": 18
        },
        {
          "t": 120,
          "y": 18
        },
        {
          "t": 121,
          "y": 18
        },
        {
          "t": 122,
          "y": 18
        },
        {
          "t": 123,
          "y": 18
        },
        {
          "t": 124,
          "y": 18
        },
        {
          "t": 125,
          "y": 18
        },
        {
          "t": 126,
          "y": 18
        },
        {
          "t": 127,
          "y": 18
        },
        {
          "t": 128,
          "y": 18
        },
        {
          "t": 129,
          "y": 18
        },
        {
          "t": 130,
          "y": 18
        },
        {
          "t": 131,
          "y": 18
        },
        {
          "t": 132,
          "y": 18
        },
        {
          "t": 133,
          "y": 18
        },
        {
          "t": 134,
          "y": 18
        },
        {
          "t": 135,
          "y": 18
        },
        {
          "t": 136,
          "y": 18
        },
        {
          "t": 137,
          "y": 18
        },
        {
          "t": 138,
          "y": 18
        },
        {
          "t": 139,
          "y": 18
        },
        {
          "t": 140,
          "y": 18
        },
        {
          "t": 141,
          "y": 18
        },
        {
          "t": 142,
          "y": 18
        },
        {
          "t": 143,
          "y": 18
        },
        {
          "t": 144,
          "y": 18
        },
        {
          "t": 145,
          "y": 18
        },
        {
          "t": 146,
          "y": 18
        },
        {
          "t": 147,
          "y": 18
        },
        {
          "t": 148,
          "y": 18
        },
        {
          "t": 149,
          "y": 18
        },
        {
          "t": 150,
          "y": 18
        },
        {
          "t": 151,
          "y": 18
        },
        {
          "t": 152,
          "y": 18
        },
        {
          "t": 153,
          "y": 18
        },
        {
          "t": 154,
          "y": 18
        },
        {
          "t": 155,
          "y": 18
        },
        {
          "t": 156,
          "y": 18
        },
        {
          "t": 157,
          "y": 18
        },
        {
          "t": 158,
          "y": 18
        },
        {
          "t": 159,
          "y": 18
        },
        {
          "t": 160,
          "y": 18
        },
        {
          "t": 161,
          "y": 18
        },
        {
          "t": 162,
          "y": 18
        },
        {
          "t": 163,
          "y": 25
        },
        {
          "t": 164,
          "y": 25
        },
        {
          "t": 165,
          "y": 25
        },
        {
          "t": 166,
          "y": 25
        },
        {
          "t": 167,
          "y": 25
        },
        {
          "t": 168,
          "y": 25
        },
        {
          "t": 169,
          "y": 25
        },
        {
          "t": 170,
          "y": 25
        },
        {
          "t": 171,
          "y": 25
        },
        {
          "t": 172,
          "y": 25
        },
        {
          "t": 173,
          "y": 25
        },
        {
          "t": 174,
          "y": 25
        },
        {
          "t": 175,
          "y": 25
        },
        {
          "t": 176,
          "y": 25
        },
        {
          "t": 177,
          "y": 25
        },
        {
          "t": 178,
          "y": 25
        },
        {
          "t": 179,
          "y": 25
        },
        {
          "t": 180,
          "y": 25
        },
        {
          "t": 181,
          "y": 25
        },
        {
          "t": 182,
          "y": 25
        },
        {
          "t": 183,
          "y": 25
        },
        {
          "t": 184,
          "y": 25
        },
        {
          "t": 185,
          "y": 25
        },
        {
          "t": 186,
          "y": 25
        },
        {
          "t": 187,
          "y": 25
        },
        {
          "t": 188,
          "y": 25
        },
        {
          "t": 189,
          "y": 25
        },
        {
          "t": 190,
          "y": 25
        },
        {
          "t": 191,
          "y": 25
        },
        {
          "t": 192,
          "y": 25
        },
        {
          "t": 193,
          "y": 25
        },
        {
          "t": 194,
          "y": 25
        },
        {
          "t": 195,
          "y": 25
        },
        {
          "t": 196,
          "y": 25
        },
        {
          "t": 197,
          "y": 25
        },
        {
          "t": 198,
          "y": 25
        },
        {
          "t": 199,
          "y": 25
        }
      ],
      "rewardHistory": [
        {
          "t": 0,
          "y": 2992.8866930044014
        },
        {
          "t": 1,
          "y": 10833.517776389619
        },
        {
          "t": 2,
          "y": 11639.27153511582
        },
        {
          "t": 3,
          "y": 10471.824045754749
        },
        {
          "t": 4,
          "y": 11042.517039789178
        },
        {
          "t": 5,
          "y": 9275.645296582017
        },
        {
          "t": 6,
          "y": 9525.922710417302
        },
        {
          "t": 7,
          "y": 10466.406369756513
        },
        {
          "t": 8,
          "y": 9674.015101031666
        },
        {
          "t": 9,
          "y": 9076.218668672293
        },
        {
          "t": 10,
          "y": 9526.163966903507
        },
        {
          "t": 11,
          "y": 10018.291402009872
        },
        {
          "t": 12,
          "y": 9207.521827955457
        },
        {
          "t": 13,
          "y": 9534.51353357619
        },
        {
          "t": 14,
          "y": 8757.555363300793
        },
        {
          "t": 15,
          "y": 9144.800455108389
        },
        {
          "t": 16,
          "y": 9738.101966466826
        },
        {
          "t": 17,
          "y": 8135.961979743988
        },
        {
          "t": 18,
          "y": 8381.642000448624
        },
        {
          "t": 19,
          "y": 7995.35550854533
        },
        {
          "t": 20,
          "y": 8141.498477007004
        },
        {
          "t": 21,
          "y": 7465.692821816369
        },
        {
          "t": 22,
          "y": 7092.837528598581
        },
        {
          "t": 23,
          "y": 6578.08140686865
        },
        {
          "t": 24,
          "y": 8289.44923842953
        },
        {
          "t": 25,
          "y": 8099.599413140731
        },
        {
          "t": 26,
          "y": 7860.4562285542115
        },
        {
          "t": 27,
          "y": 7676.821282065308
        },
        {
          "t": 28,
          "y": 6397.15031313748
        },
        {
          "t": 29,
          "y": 6483.391465075299
        },
        {
          "t": 30,
          "y": 5748.500166919148
        },
        {
          "t": 31,
          "y": 6279.386905081217
        },
        {
          "t": 32,
          "y": 6877.885413641494
        },
        {
          "t": 33,
          "y": 6802.391196764411
        },
        {
          "t": 34,
          "y": 6712.018894486865
        },
        {
          "t": 35,
          "y": 6396.830010274129
        },
        {
          "t": 36,
          "y": 6312.367112685322
        },
        {
          "t": 37,
          "y": 5889.4307904912575
        },
        {
          "t": 38,
          "y": 5349.8365535854555
        },
        {
          "t": 39,
          "y": 5480.369581262394
        },
        {
          "t": 40,
          "y": 5401.258969427963
        },
        {
          "t": 41,
          "y": 5023.662293554626
        },
        {
          "t": 42,
          "y": 4789.269300755133
        },
        {
          "t": 43,
          "y": 5766.507574528959
        },
        {
          "t": 44,
          "y": 4929.042033076999
        },
        {
          "t": 45,
          "y": 4775.961752041256
        },
        {
          "t": 46,
          "y": 4854.368192904097
        },
        {
          "t": 47,
          "y": 5288.751973125891
        },
        {
          "t": 48,
          "y": 5286.224719179165
        },
        {
          "t": 49,
          "y": 3906.0256022298536
        },
        {
          "t": 50,
          "y": 5589.464838815447
        },
        {
          "t": 51,
          "y": 4479.497098229918
        },
        {
          "t": 52,
          "y": 5036.75266276954
        },
        {
          "t": 53,
          "y": 4734.718870672409
        },
        {
          "t": 54,
          "y": 4615.18069321883
        },
        {
          "t": 55,
          "y": 5474.140807129795
        },
        {
          "t": 56,
          "y": 4192.177608027121
        },
        {
          "t": 57,
          "y": 4462.188489000045
        },
        {
          "t": 58,
          "y": 3884.6352246760766
        },
        {
          "t": 59,
          "y": 4219.025032211026
        },
        {
          "t": 60,
          "y": 3781.038240706418
        },
        {
          "t": 61,
          "y": 3697.2948740189267
        },
        {
          "t": 62,
          "y": 6995.67183489533
        },
        {
          "t": 63,
          "y": 8548.679748139597
        },
        {
          "t": 64,
          "y": 7708.147234535715
        },
        {
          "t": 65,
          "y": 7821.166089919258
        },
        {
          "t": 66,
          "y": 7779.788474275676
        },
        {
          "t": 67,
          "y": 7178.463541825204
        },
        {
          "t": 68,
          "y": 6587.834931228252
        },
        {
          "t": 69,
          "y": 7696.3043911852965
        },
        {
          "t": 70,
          "y": 8273.275179589251
        },
        {
          "t": 71,
          "y": 7793.699040686109
        },
        {
          "t": 72,
          "y": 6127.7084361011
        },
        {
          "t": 73,
          "y": 7194.807230863595
        },
        {
          "t": 74,
          "y": 6488.234571670426
        },
        {
          "t": 75,
          "y": 6908.862157768825
        },
        {
          "t": 76,
          "y": 6968.4283921676215
        },
        {
          "t": 77,
          "y": 7757.604793702529
        },
        {
          "t": 78,
          "y": 6514.645952284461
        },
        {
          "t": 79,
          "y": 6292.832694074512
        },
        {
          "t": 80,
          "y": 6788.948750751584
        },
        {
          "t": 81,
          "y": 4607.848738890446
        },
        {
          "t": 82,
          "y": 5731.290486684037
        },
        {
          "t": 83,
          "y": 6791.056603848685
        },
        {
          "t": 84,
          "y": 6590.36731665608
        },
        {
          "t": 85,
          "y": 7049.711434974973
        },
        {
          "t": 86,
          "y": 6456.14729211247
        },
        {
          "t": 87,
          "y": 6852.404164056167
        },
        {
          "t": 88,
          "y": 6812.660465711972
        },
        {
          "t": 89,
          "y": 6628.897213818171
        },
        {
          "t": 90,
          "y": 8450.189513964704
        },
        {
          "t": 91,
          "y": 7867.050267186712
        },
        {
          "t": 92,
          "y": 7846.819500381722
        },
        {
          "t": 93,
          "y": 9489.10837518965
        },
        {
          "t": 94,
          "y": 8875.379493824272
        },
        {
          "t": 95,
          "y": 8351.188417446065
        },
        {
          "t": 96,
          "y": 9231.21946672861
        },
        {
          "t": 97,
          "y": 9594.48756631355
        },
        {
          "t": 98,
          "y": 9901.25036693377
        },
        {
          "t": 99,
          "y": 9613.873036720755
        },
        {
          "t": 100,
          "y": 8510.347387971598
        },
        {
          "t": 101,
          "y": 10419.475153684365
        },
        {
          "t": 102,
          "y": 10507.361023199308
        },
        {
          "t": 103,
          "y": 10035.61017069647
        },
        {
          "t": 104,
          "y": 10259.927899696542
        },
        {
          "t": 105,
          "y": 9684.052380995527
        },
        {
          "t": 106,
          "y": 9831.343267877359
        },
        {
          "t": 107,
          "y": 9927.55677750538
        },
        {
          "t": 108,
          "y": 10376.951514445449
        },
        {
          "t": 109,
          "y": 10710.825949710457
        },
        {
          "t": 110,
          "y": 11168.470063372351
        },
        {
          "t": 111,
          "y": 12135.804465331477
        },
        {
          "t": 112,
          "y": 12381.26603539238
        },
        {
          "t": 113,
          "y": 12648.596361076416
        },
        {
          "t": 114,
          "y": 12369.64587202893
        },
        {
          "t": 115,
          "y": 13748.045258556422
        },
        {
          "t": 116,
          "y": 14222.814906521859
        },
        {
          "t": 117,
          "y": 13423.507145827327
        },
        {
          "t": 118,
          "y": 13538.048911991611
        },
        {
          "t": 119,
          "y": 13319.794119322296
        },
        {
          "t": 120,
          "y": 13689.564968247309
        },
        {
          "t": 121,
          "y": 12840.397599129366
        },
        {
          "t": 122,
          "y": 8807.784078578245
        },
        {
          "t": 123,
          "y": 8804.812587182516
        },
        {
          "t": 124,
          "y": 7813.068691389031
        },
        {
          "t": 125,
          "y": 9371.324133812304
        },
        {
          "t": 126,
          "y": 9321.937217128856
        },
        {
          "t": 127,
          "y": 8839.549418154309
        },
        {
          "t": 128,
          "y": 8462.931335222067
        },
        {
          "t": 129,
          "y": 9444.959783028164
        },
        {
          "t": 130,
          "y": 9245.51264309404
        },
        {
          "t": 131,
          "y": 9073.991306700886
        },
        {
          "t": 132,
          "y": 8806.689055277624
        },
        {
          "t": 133,
          "y": 9125.347458974464
        },
        {
          "t": 134,
          "y": 8927.628328094213
        },
        {
          "t": 135,
          "y": 9373.34639024522
        },
        {
          "t": 136,
          "y": 8957.485505554545
        },
        {
          "t": 137,
          "y": 8391.537958686386
        },
        {
          "t": 138,
          "y": 9233.291210479465
        },
        {
          "t": 139,
          "y": 8482.538340993662
        },
        {
          "t": 140,
          "y": 8893.591677140848
        },
        {
          "t": 141,
          "y": 8790.572125360683
        },
        {
          "t": 142,
          "y": 8927.08065503493
        },
        {
          "t": 143,
          "y": 9267.495050056008
        },
        {
          "t": 144,
          "y": 8932.383396023459
        },
        {
          "t": 145,
          "y": 9418.558862798065
        },
        {
          "t": 146,
          "y": 9705.965315416213
        },
        {
          "t": 147,
          "y": 9468.676164274459
        },
        {
          "t": 148,
          "y": 8550.769228808831
        },
        {
          "t": 149,
          "y": 9499.990936239878
        },
        {
          "t": 150,
          "y": 10391.565382863419
        },
        {
          "t": 151,
          "y": 8875.123460126204
        },
        {
          "t": 152,
          "y": 9408.570867618231
        },
        {
          "t": 153,
          "y": 9452.297031895698
        },
        {
          "t": 154,
          "y": 9161.091878878977
        },
        {
          "t": 155,
          "y": 9897.014408240313
        },
        {
          "t": 156,
          "y": 9058.849002634723
        },
        {
          "t": 157,
          "y": 9632.13088764821
        },
        {
          "t": 158,
          "y": 8597.328069953319
        },
        {
          "t": 159,
          "y": 9162.459447460644
        },
        {
          "t": 160,
          "y": 9739.905490332745
        },
        {
          "t": 161,
          "y": 9022.440718557638
        },
        {
          "t": 162,
          "y": 0
        },
        {
          "t": 163,
          "y": 4411.32360009174
        },
        {
          "t": 164,
          "y": 4457.525475583006
        },
        {
          "t": 165,
          "y": 5346.1752232467325
        },
        {
          "t": 166,
          "y": 4486.089254237287
        },
        {
          "t": 167,
          "y": 4587.564377183772
        },
        {
          "t": 168,
          "y": 3126.63878437409
        },
        {
          "t": 169,
          "y": 5503.582734382599
        },
        {
          "t": 170,
          "y": 5118.061930795665
        },
        {
          "t": 171,
          "y": 4800.139646537073
        },
        {
          "t": 172,
          "y": 4932.041813801031
        },
        {
          "t": 173,
          "y": 4789.0588072000655
        },
        {
          "t": 174,
          "y": 3758.3698208932274
        },
        {
          "t": 175,
          "y": 5229.697824977981
        },
        {
          "t": 176,
          "y": 4926.350407499121
        },
        {
          "t": 177,
          "y": 4791.330878342402
        },
        {
          "t": 178,
          "y": 4600.767482102508
        },
        {
          "t": 179,
          "y": 4837.08696207221
        },
        {
          "t": 180,
          "y": 3759.202437352876
        },
        {
          "t": 181,
          "y": 4685.797467356168
        },
        {
          "t": 182,
          "y": 4573.202132174673
        },
        {
          "t": 183,
          "y": 4419.452514717669
        },
        {
          "t": 184,
          "y": 4280.80213147657
        },
        {
          "t": 185,
          "y": 4191.444065926185
        },
        {
          "t": 186,
          "y": 5748.15766935334
        },
        {
          "t": 187,
          "y": 4554.132338368352
        },
        {
          "t": 188,
          "y": 4588.900852121606
        },
        {
          "t": 189,
          "y": 5893.432033748643
        },
        {
          "t": 190,
          "y": 4947.279226302613
        },
        {
          "t": 191,
          "y": 5698.9698153635545
        },
        {
          "t": 192,
          "y": 4360.623353201354
        },
        {
          "t": 193,
          "y": 5779.64421959277
        },
        {
          "t": 194,
          "y": 5018.5242309885125
        },
        {
          "t": 195,
          "y": 6444.713603551873
        },
        {
          "t": 196,
          "y": 4722.758345711809
        },
        {
          "t": 197,
          "y": 5073.932419756981
        },
        {
          "t": 198,
          "y": 5193.318341675559
        },
        {
          "t": 199,
          "y": 5189.58975945573
        }
      ],
      "salesHistory": [],
      "productCost": 0,
      "_lastSelectedPrice": 25,
      "cost": 18,
      "market": {
        "populationDistribution": {
          "args": [
            [
              993,
              30
            ],
            [
              509,
              10
            ],
            [
              296,
              40
            ],
            [
              711,
              20
            ],
            [
              754,
              20
            ]
          ],
          "progressTicks": [
            0,
            0.2,
            0.4,
            0.6,
            0.8
          ],
          "distribution": {}
        },
        "demandCurve": {
          "prices": [
            15,
            16,
            18,
            21,
            25,
            30
          ],
          "amountDistributions": [
            {},
            {},
            {},
            {},
            {},
            {}
          ]
        }
      }
    }
  },
  {
    "name": "Player",
    "agent": {
      "selectedPrice": 15,
      "turnRevenue": -8878.599102232298,
      "totalRevenue": 419069.02439482155,
      "turnSales": 2959.5330340774326,
      "totalSales": 392366.9407776564,
      "rewardHistory": [
        {
          "t": 0,
          "y": 2890.2406132620886
        },
        {
          "t": 1,
          "y": 2818.0781600239725
        },
        {
          "t": 2,
          "y": 2931.464900060041
        },
        {
          "t": 3,
          "y": 2906.374970598953
        },
        {
          "t": 4,
          "y": 2691.13577508928
        },
        {
          "t": 5,
          "y": 2714.5025020470134
        },
        {
          "t": 6,
          "y": 2948.3035025861104
        },
        {
          "t": 7,
          "y": 2716.78556722299
        },
        {
          "t": 8,
          "y": 2811.974514934437
        },
        {
          "t": 9,
          "y": 2621.603642376042
        },
        {
          "t": 10,
          "y": 2596.585896696494
        },
        {
          "t": 11,
          "y": 2732.0870644675597
        },
        {
          "t": 12,
          "y": 2505.8783633239614
        },
        {
          "t": 13,
          "y": 2762.128984800936
        },
        {
          "t": 14,
          "y": 2569.6354296762406
        },
        {
          "t": 15,
          "y": 2646.69736016947
        },
        {
          "t": 16,
          "y": 2380.4196722497013
        },
        {
          "t": 17,
          "y": 2234.7092255190514
        },
        {
          "t": 18,
          "y": 2310.819109800808
        },
        {
          "t": 19,
          "y": 2282.635370909169
        },
        {
          "t": 20,
          "y": 2134.178055901135
        },
        {
          "t": 21,
          "y": 2272.4734214648656
        },
        {
          "t": 22,
          "y": 2193.1260869640582
        },
        {
          "t": 23,
          "y": 2320.792996811498
        },
        {
          "t": 24,
          "y": 1932.7275762551878
        },
        {
          "t": 25,
          "y": 2018.102652991769
        },
        {
          "t": 26,
          "y": 2220.5355799984764
        },
        {
          "t": 27,
          "y": 2031.1178863218238
        },
        {
          "t": 28,
          "y": 1857.8532104246194
        },
        {
          "t": 29,
          "y": 1964.6742583161001
        },
        {
          "t": 30,
          "y": 1962.1569142871695
        },
        {
          "t": 31,
          "y": 1808.861197381835
        },
        {
          "t": 32,
          "y": 1905.288201549228
        },
        {
          "t": 33,
          "y": 1790.3126998513005
        },
        {
          "t": 34,
          "y": 1875.2812663029056
        },
        {
          "t": 35,
          "y": 1533.0182359593105
        },
        {
          "t": 36,
          "y": 1620.2539250450268
        },
        {
          "t": 37,
          "y": 1682.416102828852
        },
        {
          "t": 38,
          "y": 1651.1367642288951
        },
        {
          "t": 39,
          "y": 1594.162765503185
        },
        {
          "t": 40,
          "y": 1560.7974782082015
        },
        {
          "t": 41,
          "y": 1511.3299279955595
        },
        {
          "t": 42,
          "y": 1591.2989763609194
        },
        {
          "t": 43,
          "y": 1526.601522491085
        },
        {
          "t": 44,
          "y": 1423.389082890798
        },
        {
          "t": 45,
          "y": 1549.9894187334571
        },
        {
          "t": 46,
          "y": 1493.431612085813
        },
        {
          "t": 47,
          "y": 1467.2025174366427
        },
        {
          "t": 48,
          "y": 1385.9760807668117
        },
        {
          "t": 49,
          "y": 1379.3464148045305
        },
        {
          "t": 50,
          "y": 1289.0584697051258
        },
        {
          "t": 51,
          "y": 1412.30733274552
        },
        {
          "t": 52,
          "y": 1407.2387099908287
        },
        {
          "t": 53,
          "y": 1412.8838421933763
        },
        {
          "t": 54,
          "y": 1230.9169142833116
        },
        {
          "t": 55,
          "y": 1160.63816785633
        },
        {
          "t": 56,
          "y": 1350.2817505774412
        },
        {
          "t": 57,
          "y": 1274.5299701886945
        },
        {
          "t": 58,
          "y": 1196.0387424020553
        },
        {
          "t": 59,
          "y": 1321.138143184239
        },
        {
          "t": 60,
          "y": 1197.5463204599928
        },
        {
          "t": 61,
          "y": 1193.749057823087
        },
        {
          "t": 62,
          "y": 6236.440367406556
        },
        {
          "t": 63,
          "y": 5836.66516363557
        },
        {
          "t": 64,
          "y": 6336.124043630699
        },
        {
          "t": 65,
          "y": 6023.8791060092935
        },
        {
          "t": 66,
          "y": 5777.520628024886
        },
        {
          "t": 67,
          "y": 5494.518960991243
        },
        {
          "t": 68,
          "y": 5602.240469639755
        },
        {
          "t": 69,
          "y": 6554.145543965535
        },
        {
          "t": 70,
          "y": 4821.709278939003
        },
        {
          "t": 71,
          "y": 5596.597619082097
        },
        {
          "t": 72,
          "y": 5520.568062680421
        },
        {
          "t": 73,
          "y": 5899.69911134651
        },
        {
          "t": 74,
          "y": 5075.9367123680695
        },
        {
          "t": 75,
          "y": 5687.001986381201
        },
        {
          "t": 76,
          "y": 4885.601006470708
        },
        {
          "t": 77,
          "y": 4748.480315388396
        },
        {
          "t": 78,
          "y": 4769.513353210679
        },
        {
          "t": 79,
          "y": 4520.836534205444
        },
        {
          "t": 80,
          "y": 4658.923611983447
        },
        {
          "t": 81,
          "y": 4299.752661384166
        },
        {
          "t": 82,
          "y": 5650.816609983383
        },
        {
          "t": 83,
          "y": 4285.056332638607
        },
        {
          "t": 84,
          "y": 4384.135328270723
        },
        {
          "t": 85,
          "y": 4201.824110187509
        },
        {
          "t": 86,
          "y": 4110.223451493931
        },
        {
          "t": 87,
          "y": 5383.522393297984
        },
        {
          "t": 88,
          "y": 6319.2622223779645
        },
        {
          "t": 89,
          "y": 6845.210035598855
        },
        {
          "t": 90,
          "y": 6212.66625381276
        },
        {
          "t": 91,
          "y": 6592.762366734391
        },
        {
          "t": 92,
          "y": 6000.894248179588
        },
        {
          "t": 93,
          "y": 6697.515105941866
        },
        {
          "t": 94,
          "y": 7236.082801034273
        },
        {
          "t": 95,
          "y": 7396.350433387039
        },
        {
          "t": 96,
          "y": 6692.570662701481
        },
        {
          "t": 97,
          "y": 7025.701444537244
        },
        {
          "t": 98,
          "y": 8228.028905464706
        },
        {
          "t": 99,
          "y": 7405.99465813625
        },
        {
          "t": 100,
          "y": 8120.144212597415
        },
        {
          "t": 101,
          "y": 6839.718411906972
        },
        {
          "t": 102,
          "y": 7921.879177797524
        },
        {
          "t": 103,
          "y": 7221.328229747377
        },
        {
          "t": 104,
          "y": 8001.07234403567
        },
        {
          "t": 105,
          "y": 7881.879125684495
        },
        {
          "t": 106,
          "y": 8782.450653317494
        },
        {
          "t": 107,
          "y": 7935.832194583438
        },
        {
          "t": 108,
          "y": 8623.500762600039
        },
        {
          "t": 109,
          "y": 9193.639868240223
        },
        {
          "t": 110,
          "y": 9027.68296011863
        },
        {
          "t": 111,
          "y": 8596.882396684352
        },
        {
          "t": 112,
          "y": 9067.011480505185
        },
        {
          "t": 113,
          "y": 9803.141667739426
        },
        {
          "t": 114,
          "y": 9829.385991175877
        },
        {
          "t": 115,
          "y": 9394.231584981939
        },
        {
          "t": 116,
          "y": 9904.92820019648
        },
        {
          "t": 117,
          "y": 10267.109972665876
        },
        {
          "t": 118,
          "y": 11490.689229445234
        },
        {
          "t": 119,
          "y": 11357.53287897522
        },
        {
          "t": 120,
          "y": 10217.013930978832
        },
        {
          "t": 121,
          "y": 11573.397085509572
        },
        {
          "t": 122,
          "y": 4292.591581434345
        },
        {
          "t": 123,
          "y": 4128.646491316154
        },
        {
          "t": 124,
          "y": 4188.6649464200445
        },
        {
          "t": 125,
          "y": 4336.392939172013
        },
        {
          "t": 126,
          "y": 4126.450170939644
        },
        {
          "t": 127,
          "y": 4156.85017435796
        },
        {
          "t": 128,
          "y": 4131.145392187344
        },
        {
          "t": 129,
          "y": 4272.440231339772
        },
        {
          "t": 130,
          "y": 4381.494661495391
        },
        {
          "t": 131,
          "y": 4597.748423754277
        },
        {
          "t": 132,
          "y": 4388.701517829031
        },
        {
          "t": 133,
          "y": 4063.0442789641666
        },
        {
          "t": 134,
          "y": 4430.72914460889
        },
        {
          "t": 135,
          "y": 4326.466903213417
        },
        {
          "t": 136,
          "y": 4288.45992427952
        },
        {
          "t": 137,
          "y": 4160.299676072385
        },
        {
          "t": 138,
          "y": 4260.691726662668
        },
        {
          "t": 139,
          "y": 4411.732577074329
        },
        {
          "t": 140,
          "y": 4361.814191672611
        },
        {
          "t": 141,
          "y": 4638.329027781196
        },
        {
          "t": 142,
          "y": 4071.8108773739164
        },
        {
          "t": 143,
          "y": 4408.0518996183655
        },
        {
          "t": 144,
          "y": 4702.94100737591
        },
        {
          "t": 145,
          "y": 4392.991456457505
        },
        {
          "t": 146,
          "y": 4369.727976620933
        },
        {
          "t": 147,
          "y": 4347.108134021227
        },
        {
          "t": 148,
          "y": 4142.8014762828425
        },
        {
          "t": 149,
          "y": 4551.589807858488
        },
        {
          "t": 150,
          "y": 4453.394515451596
        },
        {
          "t": 151,
          "y": 4796.979336172658
        },
        {
          "t": 152,
          "y": 4581.2434431679985
        },
        {
          "t": 153,
          "y": 4321.882914046895
        },
        {
          "t": 154,
          "y": 4641.43223834319
        },
        {
          "t": 155,
          "y": 4415.644896920567
        },
        {
          "t": 156,
          "y": 5004.148403542244
        },
        {
          "t": 157,
          "y": 4211.119400033924
        },
        {
          "t": 158,
          "y": 4497.19328386864
        },
        {
          "t": 159,
          "y": 4109.061048318716
        },
        {
          "t": 160,
          "y": 4629.698686630794
        },
        {
          "t": 161,
          "y": 4192.726978211969
        },
        {
          "t": 162,
          "y": -6742.575374917831
        },
        {
          "t": 163,
          "y": -6401.608228799327
        },
        {
          "t": 164,
          "y": -7272.317963584817
        },
        {
          "t": 165,
          "y": -7358.368941328563
        },
        {
          "t": 166,
          "y": -6871.856392463449
        },
        {
          "t": 167,
          "y": -7723.765758523068
        },
        {
          "t": 168,
          "y": -7286.547818971601
        },
        {
          "t": 169,
          "y": -7006.418856985889
        },
        {
          "t": 170,
          "y": -7108.1456056834295
        },
        {
          "t": 171,
          "y": -6957.886463079253
        },
        {
          "t": 172,
          "y": -7609.257484859181
        },
        {
          "t": 173,
          "y": -7567.847459076509
        },
        {
          "t": 174,
          "y": -7644.788474199749
        },
        {
          "t": 175,
          "y": -7767.186906772056
        },
        {
          "t": 176,
          "y": -7354.043636754124
        },
        {
          "t": 177,
          "y": -7177.55844175626
        },
        {
          "t": 178,
          "y": -7695.895149677437
        },
        {
          "t": 179,
          "y": -8286.654405238027
        },
        {
          "t": 180,
          "y": -7328.897734010986
        },
        {
          "t": 181,
          "y": -7521.93156542951
        },
        {
          "t": 182,
          "y": -8196.765646859485
        },
        {
          "t": 183,
          "y": -7393.401517068725
        },
        {
          "t": 184,
          "y": -7929.879389419469
        },
        {
          "t": 185,
          "y": -8457.299044390624
        },
        {
          "t": 186,
          "y": -7603.314693425875
        },
        {
          "t": 187,
          "y": -8050.952473378889
        },
        {
          "t": 188,
          "y": -7894.384511259527
        },
        {
          "t": 189,
          "y": -8096.692584885056
        },
        {
          "t": 190,
          "y": -8558.145840760422
        },
        {
          "t": 191,
          "y": -8717.688913923474
        },
        {
          "t": 192,
          "y": -9095.500907737533
        },
        {
          "t": 193,
          "y": -7956.311509549548
        },
        {
          "t": 194,
          "y": -8716.085619092764
        },
        {
          "t": 195,
          "y": -9025.93579648165
        },
        {
          "t": 196,
          "y": -8653.900848791298
        },
        {
          "t": 197,
          "y": -8636.643706761479
        },
        {
          "t": 198,
          "y": -9011.607735337753
        },
        {
          "t": 199,
          "y": -8878.599102232298
        }
      ],
      "salesHistory": [
        {
          "t": 0,
          "y": 2890.2406132620886
        },
        {
          "t": 1,
          "y": 2818.0781600239725
        },
        {
          "t": 2,
          "y": 2931.464900060041
        },
        {
          "t": 3,
          "y": 2906.374970598953
        },
        {
          "t": 4,
          "y": 2691.13577508928
        },
        {
          "t": 5,
          "y": 2714.5025020470134
        },
        {
          "t": 6,
          "y": 2948.3035025861104
        },
        {
          "t": 7,
          "y": 2716.78556722299
        },
        {
          "t": 8,
          "y": 2811.974514934437
        },
        {
          "t": 9,
          "y": 2621.603642376042
        },
        {
          "t": 10,
          "y": 2596.585896696494
        },
        {
          "t": 11,
          "y": 2732.0870644675597
        },
        {
          "t": 12,
          "y": 2505.8783633239614
        },
        {
          "t": 13,
          "y": 2762.128984800936
        },
        {
          "t": 14,
          "y": 2569.6354296762406
        },
        {
          "t": 15,
          "y": 2646.69736016947
        },
        {
          "t": 16,
          "y": 2380.4196722497013
        },
        {
          "t": 17,
          "y": 2234.7092255190514
        },
        {
          "t": 18,
          "y": 2310.819109800808
        },
        {
          "t": 19,
          "y": 2282.635370909169
        },
        {
          "t": 20,
          "y": 2134.178055901135
        },
        {
          "t": 21,
          "y": 2272.4734214648656
        },
        {
          "t": 22,
          "y": 2193.1260869640582
        },
        {
          "t": 23,
          "y": 2320.792996811498
        },
        {
          "t": 24,
          "y": 1932.7275762551878
        },
        {
          "t": 25,
          "y": 2018.102652991769
        },
        {
          "t": 26,
          "y": 2220.5355799984764
        },
        {
          "t": 27,
          "y": 2031.1178863218238
        },
        {
          "t": 28,
          "y": 1857.8532104246194
        },
        {
          "t": 29,
          "y": 1964.6742583161001
        },
        {
          "t": 30,
          "y": 1962.1569142871695
        },
        {
          "t": 31,
          "y": 1808.861197381835
        },
        {
          "t": 32,
          "y": 1905.288201549228
        },
        {
          "t": 33,
          "y": 1790.3126998513005
        },
        {
          "t": 34,
          "y": 1875.2812663029056
        },
        {
          "t": 35,
          "y": 1533.0182359593105
        },
        {
          "t": 36,
          "y": 1620.2539250450268
        },
        {
          "t": 37,
          "y": 1682.416102828852
        },
        {
          "t": 38,
          "y": 1651.1367642288951
        },
        {
          "t": 39,
          "y": 1594.162765503185
        },
        {
          "t": 40,
          "y": 1560.7974782082015
        },
        {
          "t": 41,
          "y": 1511.3299279955595
        },
        {
          "t": 42,
          "y": 1591.2989763609194
        },
        {
          "t": 43,
          "y": 1526.601522491085
        },
        {
          "t": 44,
          "y": 1423.389082890798
        },
        {
          "t": 45,
          "y": 1549.9894187334571
        },
        {
          "t": 46,
          "y": 1493.431612085813
        },
        {
          "t": 47,
          "y": 1467.2025174366427
        },
        {
          "t": 48,
          "y": 1385.9760807668117
        },
        {
          "t": 49,
          "y": 1379.3464148045305
        },
        {
          "t": 50,
          "y": 1289.0584697051258
        },
        {
          "t": 51,
          "y": 1412.30733274552
        },
        {
          "t": 52,
          "y": 1407.2387099908287
        },
        {
          "t": 53,
          "y": 1412.8838421933763
        },
        {
          "t": 54,
          "y": 1230.9169142833116
        },
        {
          "t": 55,
          "y": 1160.63816785633
        },
        {
          "t": 56,
          "y": 1350.2817505774412
        },
        {
          "t": 57,
          "y": 1274.5299701886945
        },
        {
          "t": 58,
          "y": 1196.0387424020553
        },
        {
          "t": 59,
          "y": 1321.138143184239
        },
        {
          "t": 60,
          "y": 1197.5463204599928
        },
        {
          "t": 61,
          "y": 1193.749057823087
        },
        {
          "t": 62,
          "y": 1247.2880734813111
        },
        {
          "t": 63,
          "y": 1167.333032727114
        },
        {
          "t": 64,
          "y": 1267.2248087261398
        },
        {
          "t": 65,
          "y": 1204.7758212018587
        },
        {
          "t": 66,
          "y": 1155.5041256049772
        },
        {
          "t": 67,
          "y": 1098.9037921982485
        },
        {
          "t": 68,
          "y": 1120.448093927951
        },
        {
          "t": 69,
          "y": 1310.829108793107
        },
        {
          "t": 70,
          "y": 964.3418557878006
        },
        {
          "t": 71,
          "y": 1119.3195238164194
        },
        {
          "t": 72,
          "y": 1104.113612536084
        },
        {
          "t": 73,
          "y": 1179.939822269302
        },
        {
          "t": 74,
          "y": 1015.187342473614
        },
        {
          "t": 75,
          "y": 1137.4003972762403
        },
        {
          "t": 76,
          "y": 977.1202012941416
        },
        {
          "t": 77,
          "y": 949.6960630776792
        },
        {
          "t": 78,
          "y": 953.9026706421358
        },
        {
          "t": 79,
          "y": 904.1673068410888
        },
        {
          "t": 80,
          "y": 931.7847223966894
        },
        {
          "t": 81,
          "y": 859.9505322768332
        },
        {
          "t": 82,
          "y": 1130.1633219966766
        },
        {
          "t": 83,
          "y": 857.0112665277215
        },
        {
          "t": 84,
          "y": 876.8270656541447
        },
        {
          "t": 85,
          "y": 840.3648220375019
        },
        {
          "t": 86,
          "y": 822.0446902987862
        },
        {
          "t": 87,
          "y": 1076.7044786595968
        },
        {
          "t": 88,
          "y": 1263.8524444755928
        },
        {
          "t": 89,
          "y": 1369.042007119771
        },
        {
          "t": 90,
          "y": 1242.5332507625521
        },
        {
          "t": 91,
          "y": 1318.5524733468783
        },
        {
          "t": 92,
          "y": 1200.1788496359177
        },
        {
          "t": 93,
          "y": 1339.5030211883732
        },
        {
          "t": 94,
          "y": 1447.2165602068546
        },
        {
          "t": 95,
          "y": 1479.2700866774078
        },
        {
          "t": 96,
          "y": 1338.514132540296
        },
        {
          "t": 97,
          "y": 1405.1402889074488
        },
        {
          "t": 98,
          "y": 1645.605781092941
        },
        {
          "t": 99,
          "y": 1481.19893162725
        },
        {
          "t": 100,
          "y": 1624.028842519483
        },
        {
          "t": 101,
          "y": 1367.9436823813944
        },
        {
          "t": 102,
          "y": 1584.3758355595048
        },
        {
          "t": 103,
          "y": 1444.2656459494754
        },
        {
          "t": 104,
          "y": 1600.214468807134
        },
        {
          "t": 105,
          "y": 1576.375825136899
        },
        {
          "t": 106,
          "y": 1756.4901306634988
        },
        {
          "t": 107,
          "y": 1587.1664389166876
        },
        {
          "t": 108,
          "y": 1724.7001525200078
        },
        {
          "t": 109,
          "y": 1838.7279736480446
        },
        {
          "t": 110,
          "y": 1805.536592023726
        },
        {
          "t": 111,
          "y": 1719.3764793368703
        },
        {
          "t": 112,
          "y": 1813.402296101037
        },
        {
          "t": 113,
          "y": 1960.6283335478854
        },
        {
          "t": 114,
          "y": 1965.8771982351755
        },
        {
          "t": 115,
          "y": 1878.846316996388
        },
        {
          "t": 116,
          "y": 1980.9856400392962
        },
        {
          "t": 117,
          "y": 2053.421994533175
        },
        {
          "t": 118,
          "y": 2298.1378458890467
        },
        {
          "t": 119,
          "y": 2271.5065757950442
        },
        {
          "t": 120,
          "y": 2043.4027861957663
        },
        {
          "t": 121,
          "y": 2314.6794171019146
        },
        {
          "t": 122,
          "y": 2146.2957907171726
        },
        {
          "t": 123,
          "y": 2064.323245658077
        },
        {
          "t": 124,
          "y": 2094.3324732100223
        },
        {
          "t": 125,
          "y": 2168.1964695860065
        },
        {
          "t": 126,
          "y": 2063.225085469822
        },
        {
          "t": 127,
          "y": 2078.42508717898
        },
        {
          "t": 128,
          "y": 2065.572696093672
        },
        {
          "t": 129,
          "y": 2136.220115669886
        },
        {
          "t": 130,
          "y": 2190.7473307476953
        },
        {
          "t": 131,
          "y": 2298.8742118771384
        },
        {
          "t": 132,
          "y": 2194.3507589145156
        },
        {
          "t": 133,
          "y": 2031.5221394820833
        },
        {
          "t": 134,
          "y": 2215.364572304445
        },
        {
          "t": 135,
          "y": 2163.2334516067085
        },
        {
          "t": 136,
          "y": 2144.22996213976
        },
        {
          "t": 137,
          "y": 2080.1498380361927
        },
        {
          "t": 138,
          "y": 2130.345863331334
        },
        {
          "t": 139,
          "y": 2205.8662885371646
        },
        {
          "t": 140,
          "y": 2180.9070958363054
        },
        {
          "t": 141,
          "y": 2319.164513890598
        },
        {
          "t": 142,
          "y": 2035.9054386869582
        },
        {
          "t": 143,
          "y": 2204.0259498091827
        },
        {
          "t": 144,
          "y": 2351.470503687955
        },
        {
          "t": 145,
          "y": 2196.4957282287523
        },
        {
          "t": 146,
          "y": 2184.8639883104665
        },
        {
          "t": 147,
          "y": 2173.5540670106134
        },
        {
          "t": 148,
          "y": 2071.4007381414212
        },
        {
          "t": 149,
          "y": 2275.794903929244
        },
        {
          "t": 150,
          "y": 2226.697257725798
        },
        {
          "t": 151,
          "y": 2398.489668086329
        },
        {
          "t": 152,
          "y": 2290.6217215839993
        },
        {
          "t": 153,
          "y": 2160.9414570234476
        },
        {
          "t": 154,
          "y": 2320.716119171595
        },
        {
          "t": 155,
          "y": 2207.8224484602833
        },
        {
          "t": 156,
          "y": 2502.074201771122
        },
        {
          "t": 157,
          "y": 2105.559700016962
        },
        {
          "t": 158,
          "y": 2248.59664193432
        },
        {
          "t": 159,
          "y": 2054.530524159358
        },
        {
          "t": 160,
          "y": 2314.849343315397
        },
        {
          "t": 161,
          "y": 2096.3634891059846
        },
        {
          "t": 162,
          "y": 2247.5251249726102
        },
        {
          "t": 163,
          "y": 2133.8694095997757
        },
        {
          "t": 164,
          "y": 2424.1059878616056
        },
        {
          "t": 165,
          "y": 2452.789647109521
        },
        {
          "t": 166,
          "y": 2290.618797487816
        },
        {
          "t": 167,
          "y": 2574.588586174356
        },
        {
          "t": 168,
          "y": 2428.849272990534
        },
        {
          "t": 169,
          "y": 2335.4729523286296
        },
        {
          "t": 170,
          "y": 2369.381868561143
        },
        {
          "t": 171,
          "y": 2319.2954876930844
        },
        {
          "t": 172,
          "y": 2536.419161619727
        },
        {
          "t": 173,
          "y": 2522.6158196921697
        },
        {
          "t": 174,
          "y": 2548.2628247332495
        },
        {
          "t": 175,
          "y": 2589.0623022573523
        },
        {
          "t": 176,
          "y": 2451.3478789180413
        },
        {
          "t": 177,
          "y": 2392.5194805854203
        },
        {
          "t": 178,
          "y": 2565.2983832258124
        },
        {
          "t": 179,
          "y": 2762.218135079342
        },
        {
          "t": 180,
          "y": 2442.9659113369953
        },
        {
          "t": 181,
          "y": 2507.3105218098367
        },
        {
          "t": 182,
          "y": 2732.2552156198285
        },
        {
          "t": 183,
          "y": 2464.467172356242
        },
        {
          "t": 184,
          "y": 2643.2931298064896
        },
        {
          "t": 185,
          "y": 2819.0996814635414
        },
        {
          "t": 186,
          "y": 2534.438231141958
        },
        {
          "t": 187,
          "y": 2683.65082445963
        },
        {
          "t": 188,
          "y": 2631.4615037531757
        },
        {
          "t": 189,
          "y": 2698.8975282950187
        },
        {
          "t": 190,
          "y": 2852.715280253474
        },
        {
          "t": 191,
          "y": 2905.8963046411577
        },
        {
          "t": 192,
          "y": 3031.833635912511
        },
        {
          "t": 193,
          "y": 2652.103836516516
        },
        {
          "t": 194,
          "y": 2905.3618730309213
        },
        {
          "t": 195,
          "y": 3008.6452654938835
        },
        {
          "t": 196,
          "y": 2884.6336162637663
        },
        {
          "t": 197,
          "y": 2878.8812355871596
        },
        {
          "t": 198,
          "y": 3003.8692451125844
        },
        {
          "t": 199,
          "y": 2959.5330340774326
        }
      ],
      "priceHistory": [
        {
          "t": 0,
          "y": 15
        },
        {
          "t": 1,
          "y": 15
        },
        {
          "t": 2,
          "y": 15
        },
        {
          "t": 3,
          "y": 15
        },
        {
          "t": 4,
          "y": 15
        },
        {
          "t": 5,
          "y": 15
        },
        {
          "t": 6,
          "y": 15
        },
        {
          "t": 7,
          "y": 15
        },
        {
          "t": 8,
          "y": 15
        },
        {
          "t": 9,
          "y": 15
        },
        {
          "t": 10,
          "y": 15
        },
        {
          "t": 11,
          "y": 15
        },
        {
          "t": 12,
          "y": 15
        },
        {
          "t": 13,
          "y": 15
        },
        {
          "t": 14,
          "y": 15
        },
        {
          "t": 15,
          "y": 15
        },
        {
          "t": 16,
          "y": 15
        },
        {
          "t": 17,
          "y": 15
        },
        {
          "t": 18,
          "y": 15
        },
        {
          "t": 19,
          "y": 15
        },
        {
          "t": 20,
          "y": 15
        },
        {
          "t": 21,
          "y": 15
        },
        {
          "t": 22,
          "y": 15
        },
        {
          "t": 23,
          "y": 15
        },
        {
          "t": 24,
          "y": 15
        },
        {
          "t": 25,
          "y": 15
        },
        {
          "t": 26,
          "y": 15
        },
        {
          "t": 27,
          "y": 15
        },
        {
          "t": 28,
          "y": 15
        },
        {
          "t": 29,
          "y": 15
        },
        {
          "t": 30,
          "y": 15
        },
        {
          "t": 31,
          "y": 15
        },
        {
          "t": 32,
          "y": 15
        },
        {
          "t": 33,
          "y": 15
        },
        {
          "t": 34,
          "y": 15
        },
        {
          "t": 35,
          "y": 15
        },
        {
          "t": 36,
          "y": 15
        },
        {
          "t": 37,
          "y": 15
        },
        {
          "t": 38,
          "y": 15
        },
        {
          "t": 39,
          "y": 15
        },
        {
          "t": 40,
          "y": 15
        },
        {
          "t": 41,
          "y": 15
        },
        {
          "t": 42,
          "y": 15
        },
        {
          "t": 43,
          "y": 15
        },
        {
          "t": 44,
          "y": 15
        },
        {
          "t": 45,
          "y": 15
        },
        {
          "t": 46,
          "y": 15
        },
        {
          "t": 47,
          "y": 15
        },
        {
          "t": 48,
          "y": 15
        },
        {
          "t": 49,
          "y": 15
        },
        {
          "t": 50,
          "y": 15
        },
        {
          "t": 51,
          "y": 15
        },
        {
          "t": 52,
          "y": 15
        },
        {
          "t": 53,
          "y": 15
        },
        {
          "t": 54,
          "y": 15
        },
        {
          "t": 55,
          "y": 15
        },
        {
          "t": 56,
          "y": 15
        },
        {
          "t": 57,
          "y": 15
        },
        {
          "t": 58,
          "y": 15
        },
        {
          "t": 59,
          "y": 15
        },
        {
          "t": 60,
          "y": 15
        },
        {
          "t": 61,
          "y": 15
        },
        {
          "t": 62,
          "y": 15
        },
        {
          "t": 63,
          "y": 15
        },
        {
          "t": 64,
          "y": 15
        },
        {
          "t": 65,
          "y": 15
        },
        {
          "t": 66,
          "y": 15
        },
        {
          "t": 67,
          "y": 15
        },
        {
          "t": 68,
          "y": 15
        },
        {
          "t": 69,
          "y": 15
        },
        {
          "t": 70,
          "y": 15
        },
        {
          "t": 71,
          "y": 15
        },
        {
          "t": 72,
          "y": 15
        },
        {
          "t": 73,
          "y": 15
        },
        {
          "t": 74,
          "y": 15
        },
        {
          "t": 75,
          "y": 15
        },
        {
          "t": 76,
          "y": 15
        },
        {
          "t": 77,
          "y": 15
        },
        {
          "t": 78,
          "y": 15
        },
        {
          "t": 79,
          "y": 15
        },
        {
          "t": 80,
          "y": 15
        },
        {
          "t": 81,
          "y": 15
        },
        {
          "t": 82,
          "y": 15
        },
        {
          "t": 83,
          "y": 15
        },
        {
          "t": 84,
          "y": 15
        },
        {
          "t": 85,
          "y": 15
        },
        {
          "t": 86,
          "y": 15
        },
        {
          "t": 87,
          "y": 15
        },
        {
          "t": 88,
          "y": 15
        },
        {
          "t": 89,
          "y": 15
        },
        {
          "t": 90,
          "y": 15
        },
        {
          "t": 91,
          "y": 15
        },
        {
          "t": 92,
          "y": 15
        },
        {
          "t": 93,
          "y": 15
        },
        {
          "t": 94,
          "y": 15
        },
        {
          "t": 95,
          "y": 15
        },
        {
          "t": 96,
          "y": 15
        },
        {
          "t": 97,
          "y": 15
        },
        {
          "t": 98,
          "y": 15
        },
        {
          "t": 99,
          "y": 15
        },
        {
          "t": 100,
          "y": 15
        },
        {
          "t": 101,
          "y": 15
        },
        {
          "t": 102,
          "y": 15
        },
        {
          "t": 103,
          "y": 15
        },
        {
          "t": 104,
          "y": 15
        },
        {
          "t": 105,
          "y": 15
        },
        {
          "t": 106,
          "y": 15
        },
        {
          "t": 107,
          "y": 15
        },
        {
          "t": 108,
          "y": 15
        },
        {
          "t": 109,
          "y": 15
        },
        {
          "t": 110,
          "y": 15
        },
        {
          "t": 111,
          "y": 15
        },
        {
          "t": 112,
          "y": 15
        },
        {
          "t": 113,
          "y": 15
        },
        {
          "t": 114,
          "y": 15
        },
        {
          "t": 115,
          "y": 15
        },
        {
          "t": 116,
          "y": 15
        },
        {
          "t": 117,
          "y": 15
        },
        {
          "t": 118,
          "y": 15
        },
        {
          "t": 119,
          "y": 15
        },
        {
          "t": 120,
          "y": 15
        },
        {
          "t": 121,
          "y": 15
        },
        {
          "t": 122,
          "y": 15
        },
        {
          "t": 123,
          "y": 15
        },
        {
          "t": 124,
          "y": 15
        },
        {
          "t": 125,
          "y": 15
        },
        {
          "t": 126,
          "y": 15
        },
        {
          "t": 127,
          "y": 15
        },
        {
          "t": 128,
          "y": 15
        },
        {
          "t": 129,
          "y": 15
        },
        {
          "t": 130,
          "y": 15
        },
        {
          "t": 131,
          "y": 15
        },
        {
          "t": 132,
          "y": 15
        },
        {
          "t": 133,
          "y": 15
        },
        {
          "t": 134,
          "y": 15
        },
        {
          "t": 135,
          "y": 15
        },
        {
          "t": 136,
          "y": 15
        },
        {
          "t": 137,
          "y": 15
        },
        {
          "t": 138,
          "y": 15
        },
        {
          "t": 139,
          "y": 15
        },
        {
          "t": 140,
          "y": 15
        },
        {
          "t": 141,
          "y": 15
        },
        {
          "t": 142,
          "y": 15
        },
        {
          "t": 143,
          "y": 15
        },
        {
          "t": 144,
          "y": 15
        },
        {
          "t": 145,
          "y": 15
        },
        {
          "t": 146,
          "y": 15
        },
        {
          "t": 147,
          "y": 15
        },
        {
          "t": 148,
          "y": 15
        },
        {
          "t": 149,
          "y": 15
        },
        {
          "t": 150,
          "y": 15
        },
        {
          "t": 151,
          "y": 15
        },
        {
          "t": 152,
          "y": 15
        },
        {
          "t": 153,
          "y": 15
        },
        {
          "t": 154,
          "y": 15
        },
        {
          "t": 155,
          "y": 15
        },
        {
          "t": 156,
          "y": 15
        },
        {
          "t": 157,
          "y": 15
        },
        {
          "t": 158,
          "y": 15
        },
        {
          "t": 159,
          "y": 15
        },
        {
          "t": 160,
          "y": 15
        },
        {
          "t": 161,
          "y": 15
        },
        {
          "t": 162,
          "y": 15
        },
        {
          "t": 163,
          "y": 15
        },
        {
          "t": 164,
          "y": 15
        },
        {
          "t": 165,
          "y": 15
        },
        {
          "t": 166,
          "y": 15
        },
        {
          "t": 167,
          "y": 15
        },
        {
          "t": 168,
          "y": 15
        },
        {
          "t": 169,
          "y": 15
        },
        {
          "t": 170,
          "y": 15
        },
        {
          "t": 171,
          "y": 15
        },
        {
          "t": 172,
          "y": 15
        },
        {
          "t": 173,
          "y": 15
        },
        {
          "t": 174,
          "y": 15
        },
        {
          "t": 175,
          "y": 15
        },
        {
          "t": 176,
          "y": 15
        },
        {
          "t": 177,
          "y": 15
        },
        {
          "t": 178,
          "y": 15
        },
        {
          "t": 179,
          "y": 15
        },
        {
          "t": 180,
          "y": 15
        },
        {
          "t": 181,
          "y": 15
        },
        {
          "t": 182,
          "y": 15
        },
        {
          "t": 183,
          "y": 15
        },
        {
          "t": 184,
          "y": 15
        },
        {
          "t": 185,
          "y": 15
        },
        {
          "t": 186,
          "y": 15
        },
        {
          "t": 187,
          "y": 15
        },
        {
          "t": 188,
          "y": 15
        },
        {
          "t": 189,
          "y": 15
        },
        {
          "t": 190,
          "y": 15
        },
        {
          "t": 191,
          "y": 15
        },
        {
          "t": 192,
          "y": 15
        },
        {
          "t": 193,
          "y": 15
        },
        {
          "t": 194,
          "y": 15
        },
        {
          "t": 195,
          "y": 15
        },
        {
          "t": 196,
          "y": 15
        },
        {
          "t": 197,
          "y": 15
        },
        {
          "t": 198,
          "y": 15
        },
        {
          "t": 199,
          "y": 15
        }
      ]
    }
  },
  {
    "name": "Agent",
    "agent": {
      "selectedPrice": 21,
      "turnRevenue": 5045.810521858419,
      "totalRevenue": 920324.2193275794,
      "turnSales": 1681.9368406194728,
      "totalSales": 325433.88429912867,
      "rewardHistory": [
        {
          "t": 0,
          "y": 2839.279490613618
        },
        {
          "t": 1,
          "y": 2912.8460652478243
        },
        {
          "t": 2,
          "y": 3154.1765638716934
        },
        {
          "t": 3,
          "y": 2978.562516143181
        },
        {
          "t": 4,
          "y": 2887.1152572428005
        },
        {
          "t": 5,
          "y": 2880.7467971820797
        },
        {
          "t": 6,
          "y": 2756.343700679507
        },
        {
          "t": 7,
          "y": 2969.3692663404718
        },
        {
          "t": 8,
          "y": 2536.372924095826
        },
        {
          "t": 9,
          "y": 2553.9913535364362
        },
        {
          "t": 10,
          "y": 5892.4197614523955
        },
        {
          "t": 11,
          "y": 5879.245171495842
        },
        {
          "t": 12,
          "y": 5688.379579715198
        },
        {
          "t": 13,
          "y": 5011.873839093416
        },
        {
          "t": 14,
          "y": 4793.729892459656
        },
        {
          "t": 15,
          "y": 5287.264776008493
        },
        {
          "t": 16,
          "y": 4651.460701966868
        },
        {
          "t": 17,
          "y": 4537.804551165186
        },
        {
          "t": 18,
          "y": 5086.455099341059
        },
        {
          "t": 19,
          "y": 4524.038776459731
        },
        {
          "t": 20,
          "y": 7121.835083632023
        },
        {
          "t": 21,
          "y": 7529.141048454985
        },
        {
          "t": 22,
          "y": 6871.139860958401
        },
        {
          "t": 23,
          "y": 7782.136260162941
        },
        {
          "t": 24,
          "y": 6852.515394107342
        },
        {
          "t": 25,
          "y": 6454.5007647114335
        },
        {
          "t": 26,
          "y": 7177.880816101916
        },
        {
          "t": 27,
          "y": 7290.070767210175
        },
        {
          "t": 28,
          "y": 6885.509634384714
        },
        {
          "t": 29,
          "y": 6785.46806630097
        },
        {
          "t": 30,
          "y": 6897.751735264748
        },
        {
          "t": 31,
          "y": 6418.947531622696
        },
        {
          "t": 32,
          "y": 6174.029437220811
        },
        {
          "t": 33,
          "y": 5963.241888054252
        },
        {
          "t": 34,
          "y": 5942.587477766289
        },
        {
          "t": 35,
          "y": 7097.874986063036
        },
        {
          "t": 36,
          "y": 6193.009011322908
        },
        {
          "t": 37,
          "y": 6285.312236528212
        },
        {
          "t": 38,
          "y": 6171.765136749449
        },
        {
          "t": 39,
          "y": 5580.267872637225
        },
        {
          "t": 40,
          "y": 4082.243927898353
        },
        {
          "t": 41,
          "y": 4962.775595988987
        },
        {
          "t": 42,
          "y": 4044.9778801284465
        },
        {
          "t": 43,
          "y": 3246.051571054138
        },
        {
          "t": 44,
          "y": 4056.6806618095216
        },
        {
          "t": 45,
          "y": 4630.203707502225
        },
        {
          "t": 46,
          "y": 4411.563852377052
        },
        {
          "t": 47,
          "y": 4541.175012689227
        },
        {
          "t": 48,
          "y": 4895.4057588364185
        },
        {
          "t": 49,
          "y": 3514.449949845685
        },
        {
          "t": 50,
          "y": 2024.291513199319
        },
        {
          "t": 51,
          "y": 1699.8882362584138
        },
        {
          "t": 52,
          "y": 1951.8928601247521
        },
        {
          "t": 53,
          "y": 2051.631138306863
        },
        {
          "t": 54,
          "y": 1716.9617205634383
        },
        {
          "t": 55,
          "y": 2249.3784795686192
        },
        {
          "t": 56,
          "y": 1847.3897308054268
        },
        {
          "t": 57,
          "y": 3006.7071419607964
        },
        {
          "t": 58,
          "y": 1548.1395315727468
        },
        {
          "t": 59,
          "y": 1647.8800769530883
        },
        {
          "t": 60,
          "y": 3064.395414515522
        },
        {
          "t": 61,
          "y": 3406.568997312581
        },
        {
          "t": 62,
          "y": 4328.905200080579
        },
        {
          "t": 63,
          "y": 4725.049796047538
        },
        {
          "t": 64,
          "y": 4433.322671822252
        },
        {
          "t": 65,
          "y": 5422.646304841158
        },
        {
          "t": 66,
          "y": 5186.559114109722
        },
        {
          "t": 67,
          "y": 3609.4794241621094
        },
        {
          "t": 68,
          "y": 3934.432119078042
        },
        {
          "t": 69,
          "y": 4519.578953997129
        },
        {
          "t": 70,
          "y": 4792.461842422545
        },
        {
          "t": 71,
          "y": 5614.320230306198
        },
        {
          "t": 72,
          "y": 5776.5352111333605
        },
        {
          "t": 73,
          "y": 5489.736614800015
        },
        {
          "t": 74,
          "y": 5402.791098545221
        },
        {
          "t": 75,
          "y": 5963.497648325203
        },
        {
          "t": 76,
          "y": 4497.266715931341
        },
        {
          "t": 77,
          "y": 6140.249955392645
        },
        {
          "t": 78,
          "y": 6031.0241635068805
        },
        {
          "t": 79,
          "y": 6009.078299986098
        },
        {
          "t": 80,
          "y": 6088.774164274011
        },
        {
          "t": 81,
          "y": 5426.355499463098
        },
        {
          "t": 82,
          "y": 7101.778088244346
        },
        {
          "t": 83,
          "y": 5830.42868529128
        },
        {
          "t": 84,
          "y": 7933.714057160163
        },
        {
          "t": 85,
          "y": 7847.198473037409
        },
        {
          "t": 86,
          "y": 6968.101390887752
        },
        {
          "t": 87,
          "y": 6600.439067565855
        },
        {
          "t": 88,
          "y": 7231.6575306655495
        },
        {
          "t": 89,
          "y": 7929.061144658516
        },
        {
          "t": 90,
          "y": 6394.976638469125
        },
        {
          "t": 91,
          "y": 6808.424757077781
        },
        {
          "t": 92,
          "y": 7800.88599082405
        },
        {
          "t": 93,
          "y": 7041.874026351088
        },
        {
          "t": 94,
          "y": 6395.504040955452
        },
        {
          "t": 95,
          "y": 8826.218749689511
        },
        {
          "t": 96,
          "y": 8003.56272871534
        },
        {
          "t": 97,
          "y": 8462.92035418083
        },
        {
          "t": 98,
          "y": 8882.814951186332
        },
        {
          "t": 99,
          "y": 8204.893879900505
        },
        {
          "t": 100,
          "y": 7624.0139435469355
        },
        {
          "t": 101,
          "y": 7857.224400412473
        },
        {
          "t": 102,
          "y": 8088.050695835145
        },
        {
          "t": 103,
          "y": 7386.211958262369
        },
        {
          "t": 104,
          "y": 8290.54545477309
        },
        {
          "t": 105,
          "y": 8758.33212988102
        },
        {
          "t": 106,
          "y": 8740.28108045731
        },
        {
          "t": 107,
          "y": 8460.8098665136
        },
        {
          "t": 108,
          "y": 8144.948267985369
        },
        {
          "t": 109,
          "y": 8900.864086076439
        },
        {
          "t": 110,
          "y": 10350.969133113218
        },
        {
          "t": 111,
          "y": 10652.155754470088
        },
        {
          "t": 112,
          "y": 10959.141581983295
        },
        {
          "t": 113,
          "y": 10602.884437350227
        },
        {
          "t": 114,
          "y": 12368.588851991739
        },
        {
          "t": 115,
          "y": 11564.141139326119
        },
        {
          "t": 116,
          "y": 12776.373639639925
        },
        {
          "t": 117,
          "y": 12281.895499792572
        },
        {
          "t": 118,
          "y": 13045.445820965488
        },
        {
          "t": 119,
          "y": 11922.055962535025
        },
        {
          "t": 120,
          "y": 12787.759641511184
        },
        {
          "t": 121,
          "y": 12546.045584116315
        },
        {
          "t": 122,
          "y": 6415.672992938584
        },
        {
          "t": 123,
          "y": 6440.902377376906
        },
        {
          "t": 124,
          "y": 6606.918983626294
        },
        {
          "t": 125,
          "y": 6284.729023145504
        },
        {
          "t": 126,
          "y": 6796.055378416565
        },
        {
          "t": 127,
          "y": 6256.418024782912
        },
        {
          "t": 128,
          "y": 6413.186249253284
        },
        {
          "t": 129,
          "y": 6467.79091712858
        },
        {
          "t": 130,
          "y": 6694.796091102027
        },
        {
          "t": 131,
          "y": 6651.943052900073
        },
        {
          "t": 132,
          "y": 6289.300837496251
        },
        {
          "t": 133,
          "y": 6448.364061821771
        },
        {
          "t": 134,
          "y": 6476.026169570996
        },
        {
          "t": 135,
          "y": 6353.872297791953
        },
        {
          "t": 136,
          "y": 6330.465617503526
        },
        {
          "t": 137,
          "y": 7087.68480610253
        },
        {
          "t": 138,
          "y": 6512.874175454923
        },
        {
          "t": 139,
          "y": 6866.1304442218625
        },
        {
          "t": 140,
          "y": 7288.170287275787
        },
        {
          "t": 141,
          "y": 6686.517506442175
        },
        {
          "t": 142,
          "y": 6288.139767108942
        },
        {
          "t": 143,
          "y": 6958.257901098466
        },
        {
          "t": 144,
          "y": 6367.513168586631
        },
        {
          "t": 145,
          "y": 6794.730862546176
        },
        {
          "t": 146,
          "y": 6534.333573772257
        },
        {
          "t": 147,
          "y": 6763.9821794710315
        },
        {
          "t": 148,
          "y": 6588.5054404552175
        },
        {
          "t": 149,
          "y": 6501.014392995305
        },
        {
          "t": 150,
          "y": 4464.818785534376
        },
        {
          "t": 151,
          "y": 4677.482095677029
        },
        {
          "t": 152,
          "y": 4523.780123265907
        },
        {
          "t": 153,
          "y": 4716.463257781367
        },
        {
          "t": 154,
          "y": 4479.0851396559065
        },
        {
          "t": 155,
          "y": 4476.378068475557
        },
        {
          "t": 156,
          "y": 4571.985910155462
        },
        {
          "t": 157,
          "y": 5018.4953689516515
        },
        {
          "t": 158,
          "y": 4147.312161365237
        },
        {
          "t": 159,
          "y": 4508.889466615253
        },
        {
          "t": 160,
          "y": 4364.121879467339
        },
        {
          "t": 161,
          "y": 4353.995687350627
        },
        {
          "t": 162,
          "y": -6593.726777489421
        },
        {
          "t": 163,
          "y": -6881.1796918869795
        },
        {
          "t": 164,
          "y": -7615.20196351973
        },
        {
          "t": 165,
          "y": -6785.859972104325
        },
        {
          "t": 166,
          "y": -7246.598802459075
        },
        {
          "t": 167,
          "y": -6662.971578642217
        },
        {
          "t": 168,
          "y": -7658.742168871736
        },
        {
          "t": 169,
          "y": -7455.762436246562
        },
        {
          "t": 170,
          "y": -5154.737488069774
        },
        {
          "t": 171,
          "y": -4603.211666894869
        },
        {
          "t": 172,
          "y": -4688.901636719984
        },
        {
          "t": 173,
          "y": -4800.110910796712
        },
        {
          "t": 174,
          "y": -5090.6647511883275
        },
        {
          "t": 175,
          "y": -5254.977771682942
        },
        {
          "t": 176,
          "y": -5461.547231574992
        },
        {
          "t": 177,
          "y": -5029.157314128322
        },
        {
          "t": 178,
          "y": -4569.141380177844
        },
        {
          "t": 179,
          "y": -5330.75970226672
        },
        {
          "t": 180,
          "y": 0
        },
        {
          "t": 181,
          "y": 0
        },
        {
          "t": 182,
          "y": 0
        },
        {
          "t": 183,
          "y": 0
        },
        {
          "t": 184,
          "y": 0
        },
        {
          "t": 185,
          "y": 0
        },
        {
          "t": 186,
          "y": 0
        },
        {
          "t": 187,
          "y": 0
        },
        {
          "t": 188,
          "y": 0
        },
        {
          "t": 189,
          "y": 0
        },
        {
          "t": 190,
          "y": 4515.432926564288
        },
        {
          "t": 191,
          "y": 4136.293222488764
        },
        {
          "t": 192,
          "y": 4011.3557970307884
        },
        {
          "t": 193,
          "y": 4020.5650337559723
        },
        {
          "t": 194,
          "y": 4076.3897308837354
        },
        {
          "t": 195,
          "y": 4802.492464221832
        },
        {
          "t": 196,
          "y": 4897.298900853926
        },
        {
          "t": 197,
          "y": 5284.489069118637
        },
        {
          "t": 198,
          "y": 4287.868014579232
        },
        {
          "t": 199,
          "y": 5045.810521858419
        }
      ],
      "salesHistory": [
        {
          "t": 0,
          "y": 2839.279490613618
        },
        {
          "t": 1,
          "y": 2912.8460652478243
        },
        {
          "t": 2,
          "y": 3154.1765638716934
        },
        {
          "t": 3,
          "y": 2978.562516143181
        },
        {
          "t": 4,
          "y": 2887.1152572428005
        },
        {
          "t": 5,
          "y": 2880.7467971820797
        },
        {
          "t": 6,
          "y": 2756.343700679507
        },
        {
          "t": 7,
          "y": 2969.3692663404718
        },
        {
          "t": 8,
          "y": 2536.372924095826
        },
        {
          "t": 9,
          "y": 2553.9913535364362
        },
        {
          "t": 10,
          "y": 2946.2098807261978
        },
        {
          "t": 11,
          "y": 2939.622585747921
        },
        {
          "t": 12,
          "y": 2844.189789857599
        },
        {
          "t": 13,
          "y": 2505.936919546708
        },
        {
          "t": 14,
          "y": 2396.864946229828
        },
        {
          "t": 15,
          "y": 2643.6323880042464
        },
        {
          "t": 16,
          "y": 2325.730350983434
        },
        {
          "t": 17,
          "y": 2268.902275582593
        },
        {
          "t": 18,
          "y": 2543.2275496705297
        },
        {
          "t": 19,
          "y": 2262.0193882298654
        },
        {
          "t": 20,
          "y": 1780.4587709080058
        },
        {
          "t": 21,
          "y": 1882.2852621137463
        },
        {
          "t": 22,
          "y": 1717.7849652396003
        },
        {
          "t": 23,
          "y": 1945.5340650407352
        },
        {
          "t": 24,
          "y": 1713.1288485268356
        },
        {
          "t": 25,
          "y": 1613.6251911778584
        },
        {
          "t": 26,
          "y": 1794.470204025479
        },
        {
          "t": 27,
          "y": 1822.5176918025438
        },
        {
          "t": 28,
          "y": 1721.3774085961786
        },
        {
          "t": 29,
          "y": 1696.3670165752426
        },
        {
          "t": 30,
          "y": 985.3931050378212
        },
        {
          "t": 31,
          "y": 916.992504517528
        },
        {
          "t": 32,
          "y": 882.0042053172587
        },
        {
          "t": 33,
          "y": 851.8916982934645
        },
        {
          "t": 34,
          "y": 848.941068252327
        },
        {
          "t": 35,
          "y": 1013.982140866148
        },
        {
          "t": 36,
          "y": 884.7155730461297
        },
        {
          "t": 37,
          "y": 897.901748075459
        },
        {
          "t": 38,
          "y": 881.6807338213498
        },
        {
          "t": 39,
          "y": 797.1811246624607
        },
        {
          "t": 40,
          "y": 371.1130843543957
        },
        {
          "t": 41,
          "y": 451.1614178171807
        },
        {
          "t": 42,
          "y": 367.72526182985877
        },
        {
          "t": 43,
          "y": 295.095597368558
        },
        {
          "t": 44,
          "y": 368.78915107359285
        },
        {
          "t": 45,
          "y": 420.92760977292954
        },
        {
          "t": 46,
          "y": 401.0512593070048
        },
        {
          "t": 47,
          "y": 412.83409206265696
        },
        {
          "t": 48,
          "y": 445.0368871669471
        },
        {
          "t": 49,
          "y": 319.49544998597133
        },
        {
          "t": 50,
          "y": 126.51821957495744
        },
        {
          "t": 51,
          "y": 106.24301476615086
        },
        {
          "t": 52,
          "y": 121.99330375779701
        },
        {
          "t": 53,
          "y": 128.22694614417892
        },
        {
          "t": 54,
          "y": 107.3101075352149
        },
        {
          "t": 55,
          "y": 140.5861549730387
        },
        {
          "t": 56,
          "y": 115.46185817533917
        },
        {
          "t": 57,
          "y": 187.91919637254978
        },
        {
          "t": 58,
          "y": 96.75872072329668
        },
        {
          "t": 59,
          "y": 102.99250480956802
        },
        {
          "t": 60,
          "y": 278.58140131959294
        },
        {
          "t": 61,
          "y": 309.6880906647801
        },
        {
          "t": 62,
          "y": 288.59368000537194
        },
        {
          "t": 63,
          "y": 315.00331973650253
        },
        {
          "t": 64,
          "y": 295.55484478815015
        },
        {
          "t": 65,
          "y": 361.5097536560772
        },
        {
          "t": 66,
          "y": 345.7706076073148
        },
        {
          "t": 67,
          "y": 240.63196161080728
        },
        {
          "t": 68,
          "y": 262.2954746052028
        },
        {
          "t": 69,
          "y": 301.3052635998086
        },
        {
          "t": 70,
          "y": 435.6783493111404
        },
        {
          "t": 71,
          "y": 510.39274820965437
        },
        {
          "t": 72,
          "y": 525.1395646484873
        },
        {
          "t": 73,
          "y": 499.0669649818195
        },
        {
          "t": 74,
          "y": 491.16282714047463
        },
        {
          "t": 75,
          "y": 542.1361498477457
        },
        {
          "t": 76,
          "y": 408.84242872103107
        },
        {
          "t": 77,
          "y": 558.2045413993313
        },
        {
          "t": 78,
          "y": 548.274923955171
        },
        {
          "t": 79,
          "y": 546.2798454532816
        },
        {
          "t": 80,
          "y": 761.0967705342514
        },
        {
          "t": 81,
          "y": 678.2944374328872
        },
        {
          "t": 82,
          "y": 887.7222610305432
        },
        {
          "t": 83,
          "y": 728.80358566141
        },
        {
          "t": 84,
          "y": 991.7142571450204
        },
        {
          "t": 85,
          "y": 980.8998091296761
        },
        {
          "t": 86,
          "y": 871.012673860969
        },
        {
          "t": 87,
          "y": 825.0548834457319
        },
        {
          "t": 88,
          "y": 903.9571913331937
        },
        {
          "t": 89,
          "y": 991.1326430823145
        },
        {
          "t": 90,
          "y": 1065.8294397448542
        },
        {
          "t": 91,
          "y": 1134.7374595129634
        },
        {
          "t": 92,
          "y": 1300.1476651373416
        },
        {
          "t": 93,
          "y": 1173.6456710585146
        },
        {
          "t": 94,
          "y": 1065.917340159242
        },
        {
          "t": 95,
          "y": 1471.0364582815853
        },
        {
          "t": 96,
          "y": 1333.9271214525568
        },
        {
          "t": 97,
          "y": 1410.4867256968048
        },
        {
          "t": 98,
          "y": 1480.4691585310554
        },
        {
          "t": 99,
          "y": 1367.4823133167508
        },
        {
          "t": 100,
          "y": 1524.8027887093872
        },
        {
          "t": 101,
          "y": 1571.4448800824946
        },
        {
          "t": 102,
          "y": 1617.610139167029
        },
        {
          "t": 103,
          "y": 1477.2423916524738
        },
        {
          "t": 104,
          "y": 1658.1090909546178
        },
        {
          "t": 105,
          "y": 1751.666425976204
        },
        {
          "t": 106,
          "y": 1748.056216091462
        },
        {
          "t": 107,
          "y": 1692.16197330272
        },
        {
          "t": 108,
          "y": 1628.9896535970738
        },
        {
          "t": 109,
          "y": 1780.172817215288
        },
        {
          "t": 110,
          "y": 1725.1615221855363
        },
        {
          "t": 111,
          "y": 1775.3592924116815
        },
        {
          "t": 112,
          "y": 1826.5235969972157
        },
        {
          "t": 113,
          "y": 1767.147406225038
        },
        {
          "t": 114,
          "y": 2061.4314753319563
        },
        {
          "t": 115,
          "y": 1927.3568565543533
        },
        {
          "t": 116,
          "y": 2129.3956066066544
        },
        {
          "t": 117,
          "y": 2046.9825832987622
        },
        {
          "t": 118,
          "y": 2174.2409701609145
        },
        {
          "t": 119,
          "y": 1987.009327089171
        },
        {
          "t": 120,
          "y": 2131.2932735851973
        },
        {
          "t": 121,
          "y": 2091.007597352719
        },
        {
          "t": 122,
          "y": 2138.557664312861
        },
        {
          "t": 123,
          "y": 2146.9674591256353
        },
        {
          "t": 124,
          "y": 2202.306327875431
        },
        {
          "t": 125,
          "y": 2094.9096743818345
        },
        {
          "t": 126,
          "y": 2265.3517928055217
        },
        {
          "t": 127,
          "y": 2085.4726749276374
        },
        {
          "t": 128,
          "y": 2137.7287497510947
        },
        {
          "t": 129,
          "y": 2155.9303057095267
        },
        {
          "t": 130,
          "y": 2231.598697034009
        },
        {
          "t": 131,
          "y": 2217.314350966691
        },
        {
          "t": 132,
          "y": 2096.4336124987503
        },
        {
          "t": 133,
          "y": 2149.454687273924
        },
        {
          "t": 134,
          "y": 2158.6753898569987
        },
        {
          "t": 135,
          "y": 2117.9574325973176
        },
        {
          "t": 136,
          "y": 2110.1552058345087
        },
        {
          "t": 137,
          "y": 2362.5616020341768
        },
        {
          "t": 138,
          "y": 2170.958058484974
        },
        {
          "t": 139,
          "y": 2288.7101480739543
        },
        {
          "t": 140,
          "y": 2429.3900957585956
        },
        {
          "t": 141,
          "y": 2228.839168814058
        },
        {
          "t": 142,
          "y": 2096.046589036314
        },
        {
          "t": 143,
          "y": 2319.4193003661553
        },
        {
          "t": 144,
          "y": 2122.504389528877
        },
        {
          "t": 145,
          "y": 2264.9102875153917
        },
        {
          "t": 146,
          "y": 2178.1111912574193
        },
        {
          "t": 147,
          "y": 2254.6607264903437
        },
        {
          "t": 148,
          "y": 2196.168480151739
        },
        {
          "t": 149,
          "y": 2167.0047976651017
        },
        {
          "t": 150,
          "y": 2232.409392767188
        },
        {
          "t": 151,
          "y": 2338.7410478385145
        },
        {
          "t": 152,
          "y": 2261.8900616329533
        },
        {
          "t": 153,
          "y": 2358.2316288906836
        },
        {
          "t": 154,
          "y": 2239.5425698279532
        },
        {
          "t": 155,
          "y": 2238.1890342377783
        },
        {
          "t": 156,
          "y": 2285.992955077731
        },
        {
          "t": 157,
          "y": 2509.2476844758257
        },
        {
          "t": 158,
          "y": 2073.6560806826187
        },
        {
          "t": 159,
          "y": 2254.4447333076264
        },
        {
          "t": 160,
          "y": 2182.0609397336693
        },
        {
          "t": 161,
          "y": 2176.9978436753136
        },
        {
          "t": 162,
          "y": 2197.908925829807
        },
        {
          "t": 163,
          "y": 2293.7265639623265
        },
        {
          "t": 164,
          "y": 2538.4006545065768
        },
        {
          "t": 165,
          "y": 2261.953324034775
        },
        {
          "t": 166,
          "y": 2415.532934153025
        },
        {
          "t": 167,
          "y": 2220.9905262140724
        },
        {
          "t": 168,
          "y": 2552.9140562905786
        },
        {
          "t": 169,
          "y": 2485.2541454155207
        },
        {
          "t": 170,
          "y": 2577.368744034887
        },
        {
          "t": 171,
          "y": 2301.6058334474346
        },
        {
          "t": 172,
          "y": 2344.450818359992
        },
        {
          "t": 173,
          "y": 2400.055455398356
        },
        {
          "t": 174,
          "y": 2545.3323755941638
        },
        {
          "t": 175,
          "y": 2627.488885841471
        },
        {
          "t": 176,
          "y": 2730.773615787496
        },
        {
          "t": 177,
          "y": 2514.578657064161
        },
        {
          "t": 178,
          "y": 2284.570690088922
        },
        {
          "t": 179,
          "y": 2665.37985113336
        },
        {
          "t": 180,
          "y": 2123.620098591168
        },
        {
          "t": 181,
          "y": 2297.781324250836
        },
        {
          "t": 182,
          "y": 2284.419158988595
        },
        {
          "t": 183,
          "y": 2112.966040446435
        },
        {
          "t": 184,
          "y": 2159.146840842361
        },
        {
          "t": 185,
          "y": 2249.0901920752385
        },
        {
          "t": 186,
          "y": 2222.51093078229
        },
        {
          "t": 187,
          "y": 2347.0614469481793
        },
        {
          "t": 188,
          "y": 2291.341678730325
        },
        {
          "t": 189,
          "y": 2360.899473230674
        },
        {
          "t": 190,
          "y": 1505.1443088547626
        },
        {
          "t": 191,
          "y": 1378.7644074962545
        },
        {
          "t": 192,
          "y": 1337.1185990102629
        },
        {
          "t": 193,
          "y": 1340.188344585324
        },
        {
          "t": 194,
          "y": 1358.7965769612451
        },
        {
          "t": 195,
          "y": 1600.8308214072772
        },
        {
          "t": 196,
          "y": 1632.4329669513088
        },
        {
          "t": 197,
          "y": 1761.496356372879
        },
        {
          "t": 198,
          "y": 1429.2893381930774
        },
        {
          "t": 199,
          "y": 1681.9368406194728
        }
      ],
      "priceHistory": [
        {
          "t": 0,
          "y": 15
        },
        {
          "t": 1,
          "y": 15
        },
        {
          "t": 2,
          "y": 15
        },
        {
          "t": 3,
          "y": 15
        },
        {
          "t": 4,
          "y": 15
        },
        {
          "t": 5,
          "y": 15
        },
        {
          "t": 6,
          "y": 15
        },
        {
          "t": 7,
          "y": 15
        },
        {
          "t": 8,
          "y": 15
        },
        {
          "t": 9,
          "y": 15
        },
        {
          "t": 10,
          "y": 16
        },
        {
          "t": 11,
          "y": 16
        },
        {
          "t": 12,
          "y": 16
        },
        {
          "t": 13,
          "y": 16
        },
        {
          "t": 14,
          "y": 16
        },
        {
          "t": 15,
          "y": 16
        },
        {
          "t": 16,
          "y": 16
        },
        {
          "t": 17,
          "y": 16
        },
        {
          "t": 18,
          "y": 16
        },
        {
          "t": 19,
          "y": 16
        },
        {
          "t": 20,
          "y": 18
        },
        {
          "t": 21,
          "y": 18
        },
        {
          "t": 22,
          "y": 18
        },
        {
          "t": 23,
          "y": 18
        },
        {
          "t": 24,
          "y": 18
        },
        {
          "t": 25,
          "y": 18
        },
        {
          "t": 26,
          "y": 18
        },
        {
          "t": 27,
          "y": 18
        },
        {
          "t": 28,
          "y": 18
        },
        {
          "t": 29,
          "y": 18
        },
        {
          "t": 30,
          "y": 21
        },
        {
          "t": 31,
          "y": 21
        },
        {
          "t": 32,
          "y": 21
        },
        {
          "t": 33,
          "y": 21
        },
        {
          "t": 34,
          "y": 21
        },
        {
          "t": 35,
          "y": 21
        },
        {
          "t": 36,
          "y": 21
        },
        {
          "t": 37,
          "y": 21
        },
        {
          "t": 38,
          "y": 21
        },
        {
          "t": 39,
          "y": 21
        },
        {
          "t": 40,
          "y": 25
        },
        {
          "t": 41,
          "y": 25
        },
        {
          "t": 42,
          "y": 25
        },
        {
          "t": 43,
          "y": 25
        },
        {
          "t": 44,
          "y": 25
        },
        {
          "t": 45,
          "y": 25
        },
        {
          "t": 46,
          "y": 25
        },
        {
          "t": 47,
          "y": 25
        },
        {
          "t": 48,
          "y": 25
        },
        {
          "t": 49,
          "y": 25
        },
        {
          "t": 50,
          "y": 30
        },
        {
          "t": 51,
          "y": 30
        },
        {
          "t": 52,
          "y": 30
        },
        {
          "t": 53,
          "y": 30
        },
        {
          "t": 54,
          "y": 30
        },
        {
          "t": 55,
          "y": 30
        },
        {
          "t": 56,
          "y": 30
        },
        {
          "t": 57,
          "y": 30
        },
        {
          "t": 58,
          "y": 30
        },
        {
          "t": 59,
          "y": 30
        },
        {
          "t": 60,
          "y": 25
        },
        {
          "t": 61,
          "y": 25
        },
        {
          "t": 62,
          "y": 25
        },
        {
          "t": 63,
          "y": 25
        },
        {
          "t": 64,
          "y": 25
        },
        {
          "t": 65,
          "y": 25
        },
        {
          "t": 66,
          "y": 25
        },
        {
          "t": 67,
          "y": 25
        },
        {
          "t": 68,
          "y": 25
        },
        {
          "t": 69,
          "y": 25
        },
        {
          "t": 70,
          "y": 21
        },
        {
          "t": 71,
          "y": 21
        },
        {
          "t": 72,
          "y": 21
        },
        {
          "t": 73,
          "y": 21
        },
        {
          "t": 74,
          "y": 21
        },
        {
          "t": 75,
          "y": 21
        },
        {
          "t": 76,
          "y": 21
        },
        {
          "t": 77,
          "y": 21
        },
        {
          "t": 78,
          "y": 21
        },
        {
          "t": 79,
          "y": 21
        },
        {
          "t": 80,
          "y": 18
        },
        {
          "t": 81,
          "y": 18
        },
        {
          "t": 82,
          "y": 18
        },
        {
          "t": 83,
          "y": 18
        },
        {
          "t": 84,
          "y": 18
        },
        {
          "t": 85,
          "y": 18
        },
        {
          "t": 86,
          "y": 18
        },
        {
          "t": 87,
          "y": 18
        },
        {
          "t": 88,
          "y": 18
        },
        {
          "t": 89,
          "y": 18
        },
        {
          "t": 90,
          "y": 16
        },
        {
          "t": 91,
          "y": 16
        },
        {
          "t": 92,
          "y": 16
        },
        {
          "t": 93,
          "y": 16
        },
        {
          "t": 94,
          "y": 16
        },
        {
          "t": 95,
          "y": 16
        },
        {
          "t": 96,
          "y": 16
        },
        {
          "t": 97,
          "y": 16
        },
        {
          "t": 98,
          "y": 16
        },
        {
          "t": 99,
          "y": 16
        },
        {
          "t": 100,
          "y": 15
        },
        {
          "t": 101,
          "y": 15
        },
        {
          "t": 102,
          "y": 15
        },
        {
          "t": 103,
          "y": 15
        },
        {
          "t": 104,
          "y": 15
        },
        {
          "t": 105,
          "y": 15
        },
        {
          "t": 106,
          "y": 15
        },
        {
          "t": 107,
          "y": 15
        },
        {
          "t": 108,
          "y": 15
        },
        {
          "t": 109,
          "y": 15
        },
        {
          "t": 110,
          "y": 16
        },
        {
          "t": 111,
          "y": 16
        },
        {
          "t": 112,
          "y": 16
        },
        {
          "t": 113,
          "y": 16
        },
        {
          "t": 114,
          "y": 16
        },
        {
          "t": 115,
          "y": 16
        },
        {
          "t": 116,
          "y": 16
        },
        {
          "t": 117,
          "y": 16
        },
        {
          "t": 118,
          "y": 16
        },
        {
          "t": 119,
          "y": 16
        },
        {
          "t": 120,
          "y": 16
        },
        {
          "t": 121,
          "y": 16
        },
        {
          "t": 122,
          "y": 16
        },
        {
          "t": 123,
          "y": 16
        },
        {
          "t": 124,
          "y": 16
        },
        {
          "t": 125,
          "y": 16
        },
        {
          "t": 126,
          "y": 16
        },
        {
          "t": 127,
          "y": 16
        },
        {
          "t": 128,
          "y": 16
        },
        {
          "t": 129,
          "y": 16
        },
        {
          "t": 130,
          "y": 16
        },
        {
          "t": 131,
          "y": 16
        },
        {
          "t": 132,
          "y": 16
        },
        {
          "t": 133,
          "y": 16
        },
        {
          "t": 134,
          "y": 16
        },
        {
          "t": 135,
          "y": 16
        },
        {
          "t": 136,
          "y": 16
        },
        {
          "t": 137,
          "y": 16
        },
        {
          "t": 138,
          "y": 16
        },
        {
          "t": 139,
          "y": 16
        },
        {
          "t": 140,
          "y": 16
        },
        {
          "t": 141,
          "y": 16
        },
        {
          "t": 142,
          "y": 16
        },
        {
          "t": 143,
          "y": 16
        },
        {
          "t": 144,
          "y": 16
        },
        {
          "t": 145,
          "y": 16
        },
        {
          "t": 146,
          "y": 16
        },
        {
          "t": 147,
          "y": 16
        },
        {
          "t": 148,
          "y": 16
        },
        {
          "t": 149,
          "y": 16
        },
        {
          "t": 150,
          "y": 15
        },
        {
          "t": 151,
          "y": 15
        },
        {
          "t": 152,
          "y": 15
        },
        {
          "t": 153,
          "y": 15
        },
        {
          "t": 154,
          "y": 15
        },
        {
          "t": 155,
          "y": 15
        },
        {
          "t": 156,
          "y": 15
        },
        {
          "t": 157,
          "y": 15
        },
        {
          "t": 158,
          "y": 15
        },
        {
          "t": 159,
          "y": 15
        },
        {
          "t": 160,
          "y": 15
        },
        {
          "t": 161,
          "y": 15
        },
        {
          "t": 162,
          "y": 15
        },
        {
          "t": 163,
          "y": 15
        },
        {
          "t": 164,
          "y": 15
        },
        {
          "t": 165,
          "y": 15
        },
        {
          "t": 166,
          "y": 15
        },
        {
          "t": 167,
          "y": 15
        },
        {
          "t": 168,
          "y": 15
        },
        {
          "t": 169,
          "y": 15
        },
        {
          "t": 170,
          "y": 16
        },
        {
          "t": 171,
          "y": 16
        },
        {
          "t": 172,
          "y": 16
        },
        {
          "t": 173,
          "y": 16
        },
        {
          "t": 174,
          "y": 16
        },
        {
          "t": 175,
          "y": 16
        },
        {
          "t": 176,
          "y": 16
        },
        {
          "t": 177,
          "y": 16
        },
        {
          "t": 178,
          "y": 16
        },
        {
          "t": 179,
          "y": 16
        },
        {
          "t": 180,
          "y": 18
        },
        {
          "t": 181,
          "y": 18
        },
        {
          "t": 182,
          "y": 18
        },
        {
          "t": 183,
          "y": 18
        },
        {
          "t": 184,
          "y": 18
        },
        {
          "t": 185,
          "y": 18
        },
        {
          "t": 186,
          "y": 18
        },
        {
          "t": 187,
          "y": 18
        },
        {
          "t": 188,
          "y": 18
        },
        {
          "t": 189,
          "y": 18
        },
        {
          "t": 190,
          "y": 21
        },
        {
          "t": 191,
          "y": 21
        },
        {
          "t": 192,
          "y": 21
        },
        {
          "t": 193,
          "y": 21
        },
        {
          "t": 194,
          "y": 21
        },
        {
          "t": 195,
          "y": 21
        },
        {
          "t": 196,
          "y": 21
        },
        {
          "t": 197,
          "y": 21
        },
        {
          "t": 198,
          "y": 21
        },
        {
          "t": 199,
          "y": 21
        }
      ]
    }
  }
];

const demoData = {
  sales: salesData,
  revenue: revenueData,
  finalStatisticsDemoPlayers: finalStatisticsDemoPlayers
};

export default demoData;
