import React from "react";
import PropTypes from "prop-types";
import Tour from "reactour";
import Button from "@material-ui/core/Button";

class Tutorial extends React.Component {
  render() {
    const { classes, isOpen, actions } = this.props;

    const steps = [
      {
        selector: "",
        content: ({ goTo }) => (
          <div>
            <h3 className={classes.tutorialTitle}>Defeat our pricing agent!</h3>
            In this <b>market simulation</b>, you and our agent will sell a
            product.
            <br />
            <br />
            The market behavior is <b>dynamic</b>: product cost varies and
            demand is seasonal. Additionally, the demand is different every time
            you play.
            <br />
            <br />
            You both will have the <b>same information</b>. Who will get the{" "}
            <b>greatest revenue</b>?
            <br />
            <br />
            <b>Press the ⇒ key</b>
          </div>
        ),
        position: "top",
        action: () => {
          actions.setTourState(false, false);
        }
      },
      {
        selector: `.${classes.priceSelector}`,
        content: ({ goTo }) => (
          <div>
            <h3 className={classes.tutorialTitle}>
              During the game: change your price
            </h3>
            Check if your revenue curve goes up or down as you move this slider.
            <br />
            <br />
            <b>Attention!</b> The best price changes during the game!
          </div>
        ),
        position: "right",
        action: () => {
          actions.setTourState(false, false);
        }
      },
      {
        selector: `.${classes.numericValuesPanel}`,
        content: ({ goTo }) => (
          <div>
            <h3 className={classes.tutorialTitle}>What is my profit?</h3>
            For each item sold, you get:
            <br />
            <br />
            <b>profit = price - cost</b>
            <br />
            <br />
            Your profit <b>could be negative</b>, if the cost is higher than
            your price!
          </div>
        ),
        action: () => {
          actions.setTourState(false, false);
        }
      },
      {
        selector: `.${classes.numericValuesPanel}`,
        content: ({ goTo }) => (
          <div>
            <h3 className={classes.tutorialTitle}>The cost changes!</h3>
            <b>Keep an eye here!</b> A new cost can drastically change the best
            price.
            <br />
            <br />
            The <span className={classes.redText}>red value</span> shows the new
            cost that's about to rule.
          </div>
        ),
        action: () => {
          actions.setTourState(false, true);
        }
      },
      {
        selector: `.${classes.revenuePlotPanel}`,
        content: ({ goTo }) => (
          <div>
            <h3 className={classes.tutorialTitle}>Is this a good price?</h3>
            This is the <b>instant</b> market response to your price.
            <br />
            <br />
            If the <span className={classes.blueText}>blue</span> curve goes up,
            it's a good price.
            <br />
            <br />
            <b>Note:</b>
            <br />
            <span className={classes.blueText}>Revenue</span> = <b>profit</b> x{" "}
            <span className={classes.greyText}>Sold items</span>
          </div>
        ),
        action: () => {
          actions.setTourState(false, false);
        }
      },
      {
        selector: `.${classes.competitionBarPanel}`,
        content: ({ goTo }) => (
          <div>
            <h3 className={classes.tutorialTitle}>Who wins?</h3>
            Try to <b>accumulate</b> the greatest revenue.
            <br />
            <br />
            If <span className={classes.blueText}>your bar</span> is greater
            than the <span className={classes.redText}>agent's bar</span> at the
            end, you win.
          </div>
        ),
        action: () => {
          actions.setTourState(false, false);
        }
      },
      {
        selector: `.${classes.productSelection}`,
        content: ({ goTo }) => (
          <div>
            <h3 className={classes.tutorialTitle}>Did you win?</h3>
            After you feel confident, try changing the product type.
            <br />
            <br />
            Our agent is prepared to play under any market rules.
            <br />
            <br />
            Hover over each product to see its <b>price/demand</b> behavior.
            <br />
          </div>
        ),
        position: "right",
        action: () => {
          actions.setTourState(false, false);
        }
      }
    ];

    return (
      <Tour
        closeWithMask={true}
        steps={steps}
        isOpen={isOpen}
        onRequestClose={actions.closeTour}
        rounded={5}
        startAt={0}
        lastStepNextButton={
          <Button
            variant="contained"
            size="small"
            className={classes.button}
            color="primary"
          >
            Go!
          </Button>
        }
      />
    );
  }
}

Tutorial.propTypes = {
  classes: PropTypes.object.isRequired
};

export default Tutorial;
