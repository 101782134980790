import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";

import groceriesIcon from "./img/groceries-icon.svg";
import refinedIcon from "./img/refined-icon.svg";
import serviceIcon from "./img/service-icon.svg";
import luxuryIcon from "./img/luxury-icon.svg";

import demandCurveGroceries from "./img/demand-curve-groceries.svg";
import demandCurveServices from "./img/demand-curve-services.svg";
import demandCurveRefined from "./img/demand-curve-refined.svg";
import demandCurveLuxury from "./img/demand-curve-deluxe.svg";

const styles = theme => ({
  containerPaper: {
    padding: theme.spacing.unit,
    width: "auto",
    textAlign: "center",
    background: "lightgrey"
  },
  button: {
    margin: theme.spacing.unit
  },
  buttonActive: {
    width: 55,
    height: 55,
    // backgroundColor: "rgba(181, 44, 98, 1)",
    backgroundColor: "rgb(63, 83, 175)",
    "&:hover": {
      backgroundColor: "rgb(47, 65, 153)"
      //   backgroundColor: "rgba(181, 44, 98, 1)"
    }
  },
  buttonInactive: {
    width: 50,
    height: 50,
    boxShadow: "none",
    // backgroundColor: "rgba(246, 49, 90, 1)",
    backgroundColor: "rgba(150, 150, 150, 1)",
    "&:hover": {
      backgroundColor: "#707070"
    }
  },
  boardTitle: {
    marginBottom: theme.spacing.unit * 3
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "auto 80%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center 50%"
  },
  tooltipPopper: { marginTop: -10, width: 400 },
  tooltipElement: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    border: "2px solid #909090"
  },
  tooltipTitle: { fontSize: 16, margin: theme.spacing.unit },
  tooltipDescription: { margin: theme.spacing.unit },
  curveImgContainer: { width: "100%", margin: "auto" }
});

class ProductSelection extends React.Component {
  render() {
    const { classes, tutorialClassName, gameState, actions } = this.props;
    const buttons = [
      {
        productName: "groceries",
        icon: groceriesIcon,
        title: "Basic goods, groceries",
        description:
          "Basic behavior for groceries and other replaceable basic goods: " +
          "lower price, higher demand. For lowest prices, the market's demand saturates",
        curveImg: demandCurveGroceries
      },
      {
        productName: "service",
        icon: serviceIcon,
        title: "Manufactured goods, quality services",
        description:
          "Adds a 'low perceived quality' effect to the basic demand curve, " +
          "in which consumers may discard products that seem too cheap, associating it with low " +
          "quality. That happens when the product quality cannot be verified at first sight",
        curveImg: demandCurveServices
      },
      {
        productName: "refined",
        icon: refinedIcon,
        title: "Refined items, high-end products",
        description:
          "Appart from the 'low perceived quality' effect (see previous product), adds " +
          "a 'high perceived quality' effect, where higher prices may enable access to " +
          "markets with higher expectations",
        curveImg: demandCurveRefined
      },
      {
        productName: "luxury",
        icon: luxuryIcon,
        title: "Luxury items, jewels",
        description:
          "This curve is for items where high price can be associated with some " +
          "signaling, like status or standing out the relevance of something. With that " +
          "purpose in mind, higher prices can lead to higher demand until certain point",
        curveImg: demandCurveLuxury
      }
    ];

    return (
      <React.Fragment>
        <Paper className={classes.containerPaper}>
          <div className={tutorialClassName}>
            {buttons.map((btn, index) => {
              return (
                <Tooltip
                  classes={{
                    popper: classes.tooltipPopper,
                    tooltip: classes.tooltipElement
                  }}
                  key={index}
                  title={
                    <React.Fragment>
                      <Typography
                        color="textSecondary"
                        className={classes.tooltipTitle}
                      >
                        {btn.title}
                      </Typography>
                      <Typography
                        component="p"
                        className={classes.tooltipDescription}
                      >
                        {btn.description}
                      </Typography>
                      <div className={classes.curveImgContainer}>
                        <img
                          src={btn.curveImg}
                          width="100%"
                          alt="Demand curve for this item"
                        />
                      </div>
                    </React.Fragment>
                  }
                >
                  <Button
                    variant="contained"
                    onClick={actions.selectProduct(btn.productName)}
                    className={classNames(
                      classes.button,
                      gameState.productName === btn.productName
                        ? classes.buttonActive
                        : classes.buttonInactive
                    )}
                    key={index}
                  >
                    <span
                      className={classes.imageSrc}
                      style={{ backgroundImage: `url(${btn.icon})` }}
                    />
                  </Button>
                </Tooltip>
              );
            })}
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}

ProductSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  gameState: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductSelection);
