import BaseAgent from "./baseAgent";
import Tryonumjs from "../tryonumjs";

/**
 * Select the price (action or bandit arm) that has the greatest expected revenue.
 */
export default class OptimalMarketAgent extends BaseAgent {
  constructor(prices, market) {
    super(prices);
    this.cost = 0;
    this.market = market;
  }

  getName() {
    return "Optimal";
  }

  updateCost(cost) {
    this.cost = cost;
  }

  _selectPrice() {
    const bestPriceIdx = Tryonumjs.argmax(this.calculateExpectedRevenues());

    return this.prices[bestPriceIdx];
  }

  calculateExpectedRevenues() {
    const revenues = Tryonumjs.substract(this.prices, this.cost);

    const meanSales = this.market.demandCurve.amountDistributions.map(d =>
      d.mean()
    );
    return Tryonumjs.multiply(meanSales, revenues);
  }

  /**
   * The OptimalAgent behavior is unaffected by the reward, it already knows that his
   * actions are optimal, therefore giving maximum expected reward.
   */
  _reward(reward) {
    return;
  }
}
