export default class BaseDistribution {
  constructor() {
    if (this.constructor === BaseDistribution) {
      throw new TypeError(
        'Abstract class "BaseDistribution" cannot be instantiated directly.'
      );
    }
  }

  update() {
    return;
  }

  mean() {
    throw new Error("mean() has not been implemented yet");
  }

  sample() {
    throw new Error("sample() has not been implemented yet");
  }

  cdf(x) {
    throw new Error("cdf() has not been implemented yet");
  }
}
