const EPS = 1e-4;

var Tryonumjs = (function () {

  var argmin = function (values) {
    return values.map(
      (x, i) => [x, i]
    ).reduce(
      (r, a) => (a[0] < r[0] ? a : r)
    )[1];
  };

  var argmax = function (values) {
    return values.map(
      (x, i) => [x, i]
    ).reduce(
      (r, a) => (a[0] > r[0] ? a : r)
    )[1];
  };

  var repeat = function (x, length) {
    return new Array(length).fill(x);
  };

  var arange = function (stop, { start = 0, step = 1 } = {}) {
    return Array(
      Math.ceil(
        (stop - start) / step
      )
    ).fill(start).map(
      (x, y) => x + y * step
    );
  };

  var sum = function (values) {
    return values.reduce(
      (sum, num) => sum + num,
      0
    );
  };

  var randomChoice = function (values, p) {
    if (typeof values == 'number') {
      values = arange(values);
    }

    if (p === undefined) {
      p = repeat(1 / values.length, values.length);
    }

    if (Math.abs(sum(p) - 1.0) > EPS) {
      throw Error("The probability values must add up to 1");
    } else if (p.lenght !== values.lenght) {
      throw Error(
        "The number of probabilities must be equal to the number of values"
      );
    }

    const randomMarker = Math.random();

    var accumulator = 0;
    for (let idx = 0; idx < p.length; idx++) {
      const probability = p[idx];
      accumulator += probability;
      if (accumulator > randomMarker) {
        return values[idx];
      }
    }

    // Rare case where (1.0 - sum(p)) < EPS but randomMarker > sum(p)
    // Return the last value
    return values.slice(-1)[0];
  };

  return {
    ///////////////////////// Array creation ////////////////////////
    repeat: repeat,

    arange: arange,

    zeros: function (length) {
      return repeat(0, length);
    },

    ones: function (length) {
      return repeat(1, length);
    },
    /////////////////////////////////////////////////////////////////

    /////////////////////// Boolean operators ///////////////////////
    satisfy: function (values, condition) {
      return values.map(
        (x, _) => condition(x) ? 1 : 0
      );
    },

    any: function (values) {
      for (let idx = 0; idx < values.length; idx++) {
        if (typeof (values[idx]) === 'boolean') {
          if (values[idx] === true) {
            return true;
          }
        } else if (typeof (values[idx]) === 'number') {
          if (values[idx] !== 0) {
            return true;
          }
        }
      }
      return false;
    },

    where: function (values) {
      return values.map(
        (x, i) => x ? i : undefined
      ).reduce(
        (idxs, idx) => idx !== undefined ? idxs.concat(idx) : idxs,
        []
      )
    },
    /////////////////////////////////////////////////////////////////

    /////////////////////// Random operators ////////////////////////
    randomInt: function ({ min = 0, max = 10 } = {}) {
      return randomChoice(arange(max, { start: min }));
    },

    randomChoice: randomChoice,
    /////////////////////////////////////////////////////////////////

    ////////////////////// Reduction operators //////////////////////
    argmin: argmin,

    argmax: argmax,

    min: function (values) {
      return values[argmin(values)];
    },

    max: function (values) {
      return values[argmax(values)];
    },

    sum: sum,

    mean: function (values) {
      if (values.length > 0) {
        return sum(values) / values.length;
      }
      return 0;
    },
    /////////////////////////////////////////////////////////////////

    ////////////////////// Arithmetic operators /////////////////////
    add: function (a, b) {
      if (typeof a === 'number') {
        return b.map(num => a + num);
      }
      if (typeof b === 'number') {
        return a.map(num => num + b);
      }
      if (a.length !== b.length) {
        throw Error('The two arrays must have the same number of elements');
      }
      return a.map((num, idx) => num + b[idx]);
    },

    substract: function (a, b) {
      if (typeof a === 'number') {
        return b.map(num => a - num);
      }
      if (typeof b === 'number') {
        return a.map(num => num - b);
      }
      if (a.length !== b.length) {
        throw Error('The two arrays must have the same number of elements');
      }
      return a.map((num, idx) => num - b[idx]);
    },

    multiply: function (a, b) {
      if (typeof a === 'number') {
        return b.map(num => a * num);
      }
      if (typeof b === 'number') {
        return a.map(num => num * b);
      }
      if (a.length !== b.length) {
        throw Error('The two arrays must have the same number of elements');
      }
      return a.map((num, idx) => num * b[idx]);
    },

    divide: function (a, b) {
      if (typeof a === 'number') {
        return b.map(num => a / num);
      }
      if (typeof b === 'number') {
        return a.map(num => num / b);
      }
      if (a.length !== b.length) {
        throw Error('The two arrays must have the same number of elements');
      }
      return a.map((num, idx) => num / b[idx]);
    },

    sqrt: function (exponents) {
      if (typeof exponents === 'number') {
        return Math.sqrt(exponents);
      }
      return exponents.map(num => Math.sqrt(num));
    },

    exp: function (exponents) {
      if (typeof exponents === 'number') {
        return Math.exp(exponents);
      }
      return exponents.map(num => Math.exp(num));
    },

    power: function (bases, exponents) {
      if (typeof bases === 'number') {
        if (typeof exponents === 'number') {
          return bases ** exponents;
        }
        return exponents.map(exp => bases ** exp);
      }
      if (typeof exponents === 'number') {
        return bases.map(base => base ** exponents);
      }
      if (bases.length !== exponents.length) {
        throw Error('The two arrays must have the same number of elements');
      }
      return bases.map((base, idx) => base ** exponents[idx]);
    },

    minimum: function (left, right) {
      if (typeof left === 'number') {
        if (typeof right === 'number') {
          return Math.min(left, right);
        }
        return right.map(r => Math.min(left, r));
      }
      if (typeof right === 'number') {
        return left.map(l => Math.min(l, right));
      }
      if (left.length !== right.length) {
        throw Error('The two arrays must have the same number of elements');
      }
      return left.map((l, idx) => Math.min(l, right[idx]));
    },

    maximum: function (left, right) {
      if (typeof left === 'number') {
        if (typeof right === 'number') {
          return Math.max(left, right);
        }
        return right.map(r => Math.max(left, r));
      }
      if (typeof right === 'number') {
        return left.map(l => Math.max(l, right));
      }
      if (left.length !== right.length) {
        throw Error('The two arrays must have the same number of elements');
      }
      return left.map((l, idx) => Math.max(l, right[idx]));
    },
    /////////////////////////////////////////////////////////////////

  }
})();

export default Tryonumjs;
