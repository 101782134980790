import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Slider from "@material-ui/lab/Slider";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  playersPaper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center"
  },
  slider: {
    padding: "0px 0px"
  },
  sliderThumb: {
    width: "15px",
    height: "15px"
  },
  agentSlider: {},
  agentSliderTrackBefore: {
    backgroundColor: `${theme.palette.secondary.main} !important`
  },
  agentSliderThumb: {
    backgroundColor: `${theme.palette.secondary.main} !important`
  },
  button: {
    margin: theme.spacing.unit / 4,
    width: 45,
    minWidth: 45
  },
  boardTitle: { fontSize: "1.5em", textAlign: "left" },
  costChangeAlert: { margin: "auto", "& p": { fontSize: "0.8em" } }
});

class PlayerBoard extends React.Component {
  priceSlider = null; // reference to player slider node
  sliderValue = 100;

  showCostAlert(gameState) {
    let timeToAlarm = gameState.nextCostUpdateProgress - gameState.gameProgress;
    return timeToAlarm > 0 && timeToAlarm < 0.1;
  }

  receivePriceFromGame = () => {
    // Sync with game price
    const { gameState } = this.props;
    const priceIndex = gameState.prices.indexOf(gameState.player.selectedPrice);
    this.sliderValue = (priceIndex * 100) / (gameState.prices.length - 1);
  };

  // Send the slider value to set the game price
  sendPriceToGame = value => {
    const { gameState } = this.props;
    let stepSize = 100 / (gameState.prices.length - 1);
    let priceIndex = parseInt(value / stepSize);
    this.props.actions.selectPrice(gameState.prices[priceIndex]);
    this.findPriceTimeout = undefined;
  };

  handleSliderChange = (e, value) => {
    this.sendPriceToGame(value);
  };

  componentDidMount() {
    // hack to increase slider clickable area (for mobile)
    let trackDivLeft = this.priceSlider.containerRef.children[0].children[0];
    let mouseAreaExpander =
      '<div style="position: absolute; top: -50px; bottom: -20px; left: -20px; right: -20px;"> </div>';
    trackDivLeft.innerHTML = mouseAreaExpander;
    let trackDivRight = this.priceSlider.containerRef.children[0].children[2];
    trackDivRight.innerHTML = mouseAreaExpander;
  }

  render() {
    const { tutorialClassName, classes, gameState } = this.props;

    var selectedPrice;
    var sliderValue;
    if (gameState.tourState.visible) {
      selectedPrice = 21;
      sliderValue = 60;
    } else {
      this.receivePriceFromGame();
      selectedPrice = gameState.player.selectedPrice;
      sliderValue = this.sliderValue;
    }

    return (
      <React.Fragment>
        <div className={tutorialClassName}>
          <Paper className={classes.playersPaper}>
            <Grid container spacing={8}>
              <Grid item xs={8}>
                <Typography className={classes.boardTitle} color="primary">
                  Your price: {selectedPrice}
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.costChangeAlert}>
                {this.showCostAlert(gameState) ? (
                  <Typography color="secondary">
                    Look out for the cost change!
                  </Typography>
                ) : (
                  <React.Fragment />
                )}
              </Grid>
              <Grid item xs={12}>
                <Slider
                  classes={{
                    thumb: classes.sliderThumb,
                    container: classes.slider
                  }}
                  step={100 / (gameState.prices.length - 1)}
                  innerRef={node => (this.priceSlider = node)}
                  value={sliderValue}
                  onChange={this.handleSliderChange}
                />
              </Grid>
            </Grid>
          </Paper>
        </div>
      </React.Fragment>
    );
  }
}

class AgentBoard extends React.Component {
  rgbaExpectedValue = alpha => `rgba(255, 238, 88, ${alpha})`;

  render() {
    const { tutorialClassName, classes, gameState } = this.props;
    const priceIndex = gameState.prices.indexOf(gameState.agent.selectedPrice);
    return (
      <React.Fragment>
        <div className={tutorialClassName}>
          <Paper className={classes.playersPaper}>
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <Typography className={classes.boardTitle} color="secondary">
                  Agent's price: {gameState.agent.selectedPrice}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Slider
                  disabled
                  classes={{
                    thumb: classes.agentSliderThumb,
                    container: classes.agentSlider,
                    trackBefore: classes.agentSliderTrackBefore
                  }}
                  step={100 / (gameState.prices.length - 1)}
                  value={(priceIndex * 100) / (gameState.prices.length - 1)}
                />
              </Grid>
            </Grid>
          </Paper>
        </div>
      </React.Fragment>
    );
  }
}

PlayerBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  gameState: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

AgentBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  gameState: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

PlayerBoard = withStyles(styles)(PlayerBoard);
AgentBoard = withStyles(styles)(AgentBoard);
export { PlayerBoard, AgentBoard };
