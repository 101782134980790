import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import FinalStatisticsChart from "./FinalStatisticsChart.js";

const styles = theme => ({
  chartToggle: { marginRight: "50px" }
});

class FinalStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.avgWindowSize = 5;
    this.showRevenues = true;
    this.showPrices = false;

    this.state = {
      showPrices: this.showPrices,
      showRevenues: this.showRevenues
    };
  }

  handlePricesToggle = e => {
    this.setState({
      showPrices: e.target.checked,
      showRevenues: e.target.checked ? this.state.showRevenues : true
    });
  };

  handleRevenuesToggle = e => {
    this.setState({
      showRevenues: e.target.checked,
      showPrices: e.target.checked ? this.state.showPrices : true
    });
  };

  render() {
    const { tourState, tutorialClassName, classes, players } = this.props;

    return (
      <React.Fragment>
        <ExpansionPanel defaultExpanded={true} className={tutorialClassName}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography color="textSecondary" component="h2" variant="h5">
              Game History
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container alignItems="center">
              <Grid item xs>
                <FinalStatisticsChart
                  tourState={tourState}
                  players={players}
                  average={this.avgWindowSize}
                  showPrices={this.state.showPrices}
                  showRevenues={this.state.showRevenues}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  className={classes.chartToggle}
                  control={
                    <Switch
                      checked={this.state.showRevenues}
                      onChange={this.handleRevenuesToggle}
                    />
                  }
                  label="Show revenue history"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  className={classes.chartToggle}
                  control={
                    <Switch
                      checked={this.state.showPrices}
                      onChange={this.handlePricesToggle}
                    />
                  }
                  label="Show price history"
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </React.Fragment>
    );
  }
}

FinalStatistics.propTypes = {
  classes: PropTypes.object.isRequired,
  players: PropTypes.object.isRequired
};

export default withStyles(styles)(FinalStatistics);
