import Tryonumjs from "../tryonumjs";

export default class DemandCurve {
  constructor(prices, amountDistributions) {
    if (prices.length !== amountDistributions.length) {
      throw new Error("The length of the two arrays must be the same");
    }

    this.prices = prices;
    this.amountDistributions = amountDistributions;
  }

  getSales(price) {
    const filteredPrices = Tryonumjs.where(
      Tryonumjs.satisfy(this.prices, x => x === price)
    );
    if (filteredPrices.length === 0) {
      throw new Error("There's no price with value " + price + " on the price array");
    }
    if (filteredPrices.length === 1) {
      return this.amountDistributions[filteredPrices[0]].sample();
    }
    throw new Error("Price " + price + "appears more than once on the prices array");
  }

}
