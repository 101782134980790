import BaseDistribution from "./baseDistribution";
import Tryonumjs from "../tryonumjs";

/**
 * Distribution shifter used to add seasonality to a probability distribution.
 */
export default class DistributionShifter extends BaseDistribution {
  /**
   *
   * @param {class} distributionClass - The probability distribution class to be shifted
   * @param {[[number]]} args - List of arguments to be passed to the distribution class
   *  constructor. The Shifter will update the arguments using a linear interpolation
   *  between these values.
   * @param {[number]} progressTicks - Ascending list of number between 0 and 1.
   *  Each one represents at which point in the game the corresponding set of
   *  arguments should be active.
   *
   * Example: a DistributionShifter(NormalDistribution, [[100, 10], [50, 7]], [0, 0.5])
   *   will start with a normal distribution with mean 100 and standard deviation 10,
   *   updating it in a linear fashion with each call of updateDistribution(), reaching
   *   the set of parameters (50, 7) by the half of the game. Then it will return to
   *   (100, 10) until reaching the end of the game.
   */
  constructor(distributionClass, args, progressTicks) {
    super();
    this.distributionClass = distributionClass;
    this.args = args;

    // If no progressTicks argument is passed, assume equally distributed intervals.
    if (!progressTicks) {
      progressTicks = args.map((_, i) => i / args.length);
    }
    this.progressTicks = progressTicks;

    // Use the first set or argument to set the initial distribution
    this.distribution = new distributionClass(...args[0]);
  }

  mean() {
    return this.distribution.mean();
  }

  sample() {
    return this.distribution.sample();
  }

  cdf(x) {
    return this.distribution.cdf(x);
  }

  /**
   *
   * @param {gameProgress} gameProgress - Number between 0 and 1 that indicates the
   *  overall progress of the game.
   */
  update(gameProgress) {
    // Calculate which progress tick index the game is currently on
    var currentTickIdx = Tryonumjs.where(
      Tryonumjs.satisfy(this.progressTicks, x => x <= gameProgress)
    ).slice(-1)[0];

    // Calculate extremes of the current progress interval (normalized to [0.0, 1.0])
    const currentProgressTick = this.progressTicks[currentTickIdx];
    const nextProgressTick =
      currentTickIdx + 1 === this.progressTicks.length
        ? 1.0
        : this.progressTicks[currentTickIdx + 1];

    // Calculate the relative progress between the current tick and the next one
    const relativeProgress =
      (gameProgress - currentProgressTick) /
      (nextProgressTick - currentProgressTick);

    // Get the actual and next argument set
    const currentArgs = this.args[currentTickIdx];
    const nextArgs = this.args[(currentTickIdx + 1) % this.args.length];

    // Interpolate between the set of arguments using the relative progress
    const scaledArgs = Tryonumjs.add(
      currentArgs,
      Tryonumjs.multiply(
        relativeProgress,
        Tryonumjs.substract(nextArgs, currentArgs)
      )
    );

    // Instantiate the new distribution
    this.distribution = new this.distributionClass(...scaledArgs);
  }
}
