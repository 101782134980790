import BaseDistribution from "./baseDistribution";

import Prob from "prob.js";

import mathjs from "mathjs";

export default class NormalDistribution extends BaseDistribution {
  constructor(mean, std = 1.0) {
    super();
    this.distribution = Prob.normal(mean, std);
  }

  mean() {
    return this.distribution.Mean;
  }

  sample() {
    return this.distribution();
  }

  cdf(x) {
    var mean = this.distribution.Mean;
    var std = Math.sqrt(this.distribution.Variance);
    // erf returns cumulative for N(0, 1) but from -1 to 1
    return (1 + mathjs.erf((x - mean) / (Math.sqrt(2) * std))) / 2;
  }
}
